import { Helmet } from 'react-helmet';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Footer from '../../components/Footer';
import SimpleHeader from '../../components/SimpleHeader';
import SimpleLayout from '../../components/SimpleLayout';

const SearchNewVehicles: React.FC = () => {
    const navigate = useNavigate();
    const initializeWindow = () => {
        return window.location.search;
    };

    const updateZipWhenReady = () => {
        const urlParams = new URLSearchParams(initializeWindow());
        if (urlParams.get('zipCode')) {
            const zipValue = urlParams.get('zipCode');
            const zipField = document.getElementById('dtZipcode');
            const zipLabel = document.querySelector('div.dt-form-item > label');
            if (zipField) {
                const popupWrapper = document.querySelector(
                    'div.dt-popupform-wrapper'
                );
                const popupForm = document.getElementById('pop-up-form');
                if (popupForm && popupWrapper) {
                    popupWrapper.classList.add('bg-stone-200');
                    popupWrapper.classList.add('sm:max-md:p-5');
                    popupWrapper.classList.add('w-full');
                    popupForm.classList.add('max-w-[100vw]');
                    // popupWrapper.classList.remove('dt-popupform-wrapper');
                }
                (zipField as any).value = zipValue;
                zipField.classList.remove('dt-error');
                if (zipValue) {
                    zipField.classList.add('filled');
                    zipLabel?.classList.add('filled');
                } else {
                    zipField.classList.remove('filled');
                    zipLabel?.classList.remove('filled');
                }
            } else {
                setTimeout(updateZipWhenReady, 100);
            }
        }
    };

    useEffect(() => {
        updateZipWhenReady();
    }, []);
    return (
        <SimpleLayout pageTitle="Shop New Cars">
            <Helmet>
                <script
                    type="text/javascript"
                    src="https://www.rydeshopper.com/app/shoppingtools/scripts/widget-inline.js?gid=0000-4886"
                ></script>
            </Helmet>
            <div className="w-full">
                <SimpleHeader />
                <div className="sm:px-5">
                    <div id="pop-up-form" className="dt-actionable"></div>
                    <div className="mb-4 px-5">
                        <Footer />
                    </div>
                </div>
            </div>
        </SimpleLayout>
    );
};

export default SearchNewVehicles;
