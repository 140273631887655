const cache = {
    zip: '',
    loanAmount: 0,
    interestRate: 0,
    creditScore: '',
};

export const cpiLoanAmount = (e, harvester) => {
    const value = +e.value.replace(',', '');
    const variant = harvester.globals.variant;
    if (cache.loanAmount !== value) {
        harvester.dispatch('CPI Loan Amount', { value, variant });
        cache.loanAmount = value;
    }
};

export const cpiLoanAmountSlider = (e, harvester) => {
    const value = +e;
    const variant = harvester.globals.variant;
    if (cache.loanAmount !== value) {
        harvester.dispatch('CPI Loan Amount', { value, variant });
        cache.loanAmount = value;
    }
};

export const cpiCreditScore = (e, harvester) => {
    const value = e.value;
    const variant = harvester.globals.variant;
    if (cache.creditScore !== value) {
        harvester.dispatch('CPI Credit Score', { value, variant });
        cache.creditScore = value;
    }
};

export const cpiInterestRate = (e, harvester) => {
    const value = +e;
    const variant = harvester.globals.variant;
    if (cache.interestRate !== value) {
        value > 0 &&
            harvester.dispatch('CPI Interest Rate', { value, variant });
        cache.interestRate = value;
    }
};

export const cpiZipCode = (e, harvester) => {
    const value = e;
    const variant = harvester.globals.variant;
    if (cache.zip !== value) {
        harvester.dispatch('CPI Zip Code', { value, variant });
        cache.zip = value;
    }
};

export const cpiGeolocationLookupClick = (latLong, harvester) => {
    const value = latLong;
    const variant = harvester.globals.variant;
    harvester.dispatch('CPI Geolocation Lookup Click', { value, variant });
};

export const cpiShopNewClick = (harvester) => {
    const variant = harvester.globals.variant;
    harvester.dispatch('CPI Shop New Click', { variant });
};

export const cpiShopUsedClick = (harvester) => {
    const variant = harvester.globals.variant;
    harvester.dispatch('CPI Shop Used Click', { variant });
};

export const itFormEntranceClick = (harvester) => {
    const variant = harvester.globals.variant;
    harvester.dispatch('IT Form Entrance Click', { variant });
};

export const itFormSubmitClick = (harvester) => {
    const variant = harvester.globals.variant;
    harvester.dispatch('IT Form Submit Click', { variant });
};

export const itTypShopMoreUsedClick = (harvester) => {
    const variant = harvester.globals.variant;
    harvester.dispatch('IT TYP Shop More Used Cars Click', { variant });
};

export const itTypHomeClick = (harvester) => {
    const variant = harvester.globals.variant;
    harvester.dispatch('IT TYP Home Click', { variant });
};
