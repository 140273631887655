import { FormEventHandler } from 'react';
import FormInput from './FormInput';
import { PatternFormat } from 'react-number-format';

type InputProps = {
    type: 'firstName' | 'lastName' | 'email' | 'phone';
    value: string;
    errorMessage: string;
    onChange: FormEventHandler | (() => void);
};

const Input: React.FC<InputProps> = (props: InputProps) => {
    const title = () => {
        switch (props.type) {
            case 'firstName':
                return 'First Name';
                break;
            case 'lastName':
                return 'Last Name';
                break;
            case 'email':
                return 'Email';
                break;
            case 'phone':
                return 'Phone Number';
                break;
        }
    };

    return (
        <FormInput title={title()} errorMessage={props.errorMessage}>
            {props.type === 'phone' ? (
                <PatternFormat
                    className="w-full rounded border-[1px] border-stone-300"
                    format="+1 (###) ###-####"
                    allowEmptyFormatting
                    valueIsNumericString
                    mask="_"
                    value={props.value}
                    onValueChange={props.onChange as () => void}
                />
            ) : (
                <input
                    className="w-full rounded border-[1px] border-stone-300"
                    pattern="[a-zA-Z]*"
                    type={props.type === 'email' ? 'email' : 'text'}
                    value={props.value}
                    onChange={props.onChange}
                />
            )}
        </FormInput>
    );
};

export default Input;
