import React, { FocusEventHandler, FormEventHandler } from 'react';
import { BsPercent } from 'react-icons/bs';
import CurrencyFormat from 'react-currency-format';

type InterestInputProps = {
    id?: string;
    type?: string;
    value?: any;
    title?: string;
    placeholder?: string;
    tabIndex?: number;
    errorMessage?: string;
    disabled?: boolean;
    onChange?: FormEventHandler | undefined;
    onValueChange: (values: { formattedValue: any; value: any }) => void;
    onInput?: FormEventHandler | undefined;
    onFocus?: FocusEventHandler | undefined;
    onBlur?: FocusEventHandler | undefined;
    onClick?: FormEventHandler | undefined;
    isAllowed?: (values: {
        formattedValue: string;
        value: string;
        floatValue: number;
    }) => boolean;
};

const InterestInput: React.FC<InterestInputProps> = ({
    title = '',
    // ...inputProps
    value,
    id,
    onValueChange,
    onFocus,
    onBlur,
    onInput,
    onClick,
    isAllowed,
    errorMessage,
    tabIndex,
    disabled,
}) => {
    return (
        <div className="mr-0 max-w-[50%]">
            {title && (
                <p id="interestLabel" className="mb-1 text-xs text-stone-700">
                    {title}
                </p>
            )}
            <div className="relative w-full">
                <CurrencyFormat
                    aria-labelledby="interestLabel"
                    pattern="^[0-9]{0,2}\.{0,1}[0-9]*$"
                    isAllowed={isAllowed}
                    disabled={disabled}
                    inputMode="decimal"
                    value={value}
                    tabIndex={tabIndex}
                    id={id}
                    onValueChange={onValueChange}
                    onFocus={onFocus}
                    onBlur={onBlur}
                    onInput={onInput}
                    onClick={onClick}
                    className={
                        'h-12 w-full rounded border-stone-300 bg-none p-3 text-base text-stone-700 focus-visible:outline-2 focus-visible:outline-blue-light' +
                        (errorMessage
                            ? ' outline outline-red-500'
                            : ' border-[1px]')
                    }
                    thousandSeparator={true}
                    prefix={''}
                />
                <BsPercent className="absolute right-5 top-[.9375rem] text-xl text-gray-500" />
            </div>
            {errorMessage ? (
                <p className="absolute block text-left text-sm text-red-500">
                    {errorMessage}*
                </p>
            ) : null}
        </div>
    );
};

export default InterestInput;
