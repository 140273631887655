import React, { useState } from 'react';

// Below is just an example context for a user's auth token that would run through the whole app.

// This is purely an example of how all contexts should be laid out. This one will be consumed across the
// whole app so you'll see it being consumed in index.tsx

interface IAuthContext {
    token: string | null;
    updateToken: (token: string) => void;
}

const defaultState: IAuthContext = {
    token: null,
    updateToken: (token: string) => {},
};

// See App.tsx for an example where we useContext on this to consume it
export const AuthContext = React.createContext<IAuthContext>(defaultState);

// Creating the provider here so it can be consumed elsewhere
export const AuthContextProvider = (props: { children: any }) => {
    const [token, setToken] = useState(defaultState.token);

    const updateToken = (token: string) => setToken(token);

    return (
        <AuthContext.Provider
            value={{
                token,
                updateToken,
            }}
        >
            {props.children}
        </AuthContext.Provider>
    );
};
