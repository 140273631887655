import { useEffect } from 'react';
import { z } from 'zod';

export const useValidateForm = (
    formSchema,
    formState,
    setErrorState,
    setErrorDisplayState,
    submitted
) =>
    useEffect(() => {
        try {
            formSchema.parse(formState);
            if (submitted) {
                setErrorDisplayState({ valid: true, errors: {} });
            } else {
                setErrorState({ valid: true, errors: {} });
            }
        } catch (err) {
            if (err instanceof z.ZodError) {
                //Create map of errors
                const errors = {};

                //Dynamically adding each field error to the errors array
                err.issues.forEach((err) => {
                    const fieldName = err.path[0];
                    errors[fieldName] = err.message;
                });

                // The reason I do this is so errors don't display the second
                // the page renders. Once the user clicks "submit" then the
                // errors will drop off as they type
                if (submitted) {
                    setErrorDisplayState({ valid: false, errors });
                } else {
                    setErrorState({ valid: false, errors });
                }
            }
        }
    }, [formState, submitted]);
