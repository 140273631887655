import React, { useState } from 'react';
import { Helmet } from 'react-helmet';

type FilterRenderProps = {
    isFilterOpen: boolean;
    setIsFilterOpen: (state: boolean) => void;
};
type SrpLayoutProps = {
    children?: (props: FilterRenderProps) => JSX.Element | JSX.Element[];
    pageTitle?: string;
};

const SrpLayout: React.FC<SrpLayoutProps> = ({ children, pageTitle }) => {
    const [ isFilterOpen, setIsFilterOpen ] = useState(false);

    return (
        <>
            <Helmet>
                <title>
                    { pageTitle ? `${ pageTitle }` : 'Car Payment Calculator' }
                </title>
            </Helmet>
            <div className={ `flex min-h-screen flex-col bg-stone-200` + (isFilterOpen ? ' overflow-hidden h-screen' : '') }>
                <main className="mx-auto mb-auto flex h-full w-full min-w-[272px] max-w-[100%] flex-col items-center justify-start">
                    { children && children({ isFilterOpen, setIsFilterOpen }) }
                </main>
            </div>
        </>
    );
};

export default SrpLayout;
