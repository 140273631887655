import { useContext } from 'react';
import Button from './Button';
import { UsedVehicleContext } from '../context/used-vehicle-context';

type SrpFooterProps = {
    formOpen: () => void;
};

const SrpFooter: React.FC<SrpFooterProps> = (props: SrpFooterProps) => {
    const { usedVehicleState } = useContext(UsedVehicleContext);
    const { selectedKeys } = usedVehicleState;

    return (
        <div className="fixed bottom-0 flex w-screen items-center gap-x-4 border-[1px] border-stone-300 bg-white p-4">
            <Button
                textClass="text-sm font-bold"
                className="w-auto flex-grow py-2"
                disabled={selectedKeys.length === 0}
                onClick={props.formOpen}
            >
                GET PAYMENT INFO{' '}
                {`(${selectedKeys.length} Vehicle${
                    selectedKeys.length !== 1 ? 's' : ''
                })`}
            </Button>
        </div>
    );
};

export default SrpFooter;
