import React, { FocusEventHandler, FormEventHandler } from 'react';
import CurrencyFormat, { Values } from 'react-currency-format';
import Button from './Button';

type ZipCodeInputProps = {
    id?: string;
    type?: string;
    value?: any;
    title?: string;
    placeholder?: string;
    tabIndex?: number;
    errorMessage?: string;
    disabled?: boolean;
    className?: string;
    onChange?: FormEventHandler | undefined;
    onValueChange: (values: { formattedValue: any; value: any }) => void;
    onInput?: FormEventHandler | undefined;
    onFocus?: FocusEventHandler | undefined;
    onBlur?: FocusEventHandler | undefined;
    onClick?: FormEventHandler | undefined;
    isAllowed: (values: Values) => boolean;
    getLocation: () => void;
};

const ZipCodeInput: React.FC<ZipCodeInputProps> = ({
    title = '',
    // ...inputProps
    value,
    id,
    onValueChange,
    onFocus,
    onBlur,
    onInput,
    onClick,
    isAllowed,
    getLocation,
    errorMessage,
    tabIndex,
    disabled,
}) => {
    return (
        <div className="mr-0 max-w-[50%]">
            {title && (
                <p id="zipCodeLabel" className="mb-1 text-xs text-stone-700">
                    {title}
                </p>
            )}
            <div className="relative flex w-full flex-col">
                <CurrencyFormat
                    aria-labelledby="zipCodeLabel"
                    pattern="^\d{0,5}$"
                    isAllowed={isAllowed}
                    disabled={disabled}
                    inputMode="numeric"
                    value={value}
                    tabIndex={tabIndex}
                    id={id}
                    onValueChange={onValueChange}
                    onFocus={onFocus}
                    onBlur={onBlur}
                    onInput={onInput}
                    onClick={onClick}
                    className={
                        'h-12 w-full rounded border-stone-300 bg-none p-3 text-base text-stone-700 focus-visible:outline-2 focus-visible:outline-blue-light' +
                        (errorMessage
                            ? ' outline outline-red-500'
                            : ' border-[1px]')
                    }
                    prefix={''}
                />
                {errorMessage && (
                    <p className="mt-1 text-left text-xs text-red-500">
                        {errorMessage}
                    </p>
                )}
                <button
                    className="mt-1 text-left text-xs font-bold text-blue-500"
                    onClick={() => getLocation()}
                >
                    Find My Location
                </button>
            </div>
        </div>
    );
};

export default ZipCodeInput;
