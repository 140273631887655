import { FormEventHandler } from 'react';

type ButtonProps = {
    children: React.ReactNode;
    beforeIcon?: any;
    afterIcon?: any;
    tabIndex?: number;
    className?: string;
    textClass?: string;
    disabledClass?: string;
    disabledTextClass?: string;
    disabled?: boolean;
    value?: string | number;
    onClick?: FormEventHandler | undefined;
};

const Button: React.FC<ButtonProps> = (props: ButtonProps) => {
    return (
        <button
            disabled={props.disabled}
            className={
                'flex flex-row items-center justify-center rounded text-white' +
                (props.disabled == true
                    ? ' ' +
                      (props.disabledClass
                          ? props.disabledClass
                          : 'bg-neutral-400')
                    : ' ' + 'bg-blue-500') +
                (props.className ? ' ' + props.className : ' ' + 'h-full')
            }
            tabIndex={props.tabIndex}
            onClick={props.onClick}
            value={props.value}
        >
            {props.beforeIcon && <div>{props.beforeIcon}</div>}
            <p
                className={
                    'mb-0 flex w-auto items-center justify-center align-middle' +
                    (props.textClass
                        ? ' ' +
                          (props.disabled == true && props.disabledTextClass
                              ? props.disabledTextClass
                              : props.textClass)
                        : ' ' + 'text-base font-bold')
                }
            >
                {props.children}
            </p>
            {props.afterIcon && <div>{props.afterIcon}</div>}
        </button>
    );
};

export default Button;
