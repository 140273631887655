import React, { FocusEventHandler, FormEventHandler } from 'react';
import { BsCurrencyDollar } from 'react-icons/bs';
import CurrencyFormat from 'react-currency-format';

type CurrencyInputProps = {
    id?: string;
    type?: string;
    value?: any;
    title?: string;
    placeholder?: string;
    tabIndex?: number;
    errorMessage?: string;
    onChange?: FormEventHandler | undefined;
    onValueChange: (values: { formattedValue: any; value: any }) => void;
    onInput?: FormEventHandler | undefined;
    onFocus?: FocusEventHandler | undefined;
    onBlur?: FocusEventHandler | undefined;
    onClick?: FormEventHandler | undefined;
};

const CurrencyInput: React.FC<CurrencyInputProps> = ({
    title = '',
    value,
    id,
    onValueChange,
    onFocus,
    onBlur,
    onClick,
    errorMessage,
    tabIndex,
}) => {
    return (
        <div className="relative flex w-full flex-col justify-center rounded">
            {title && (
                <p
                    id="currencyLabel"
                    className={
                        'mb-1 text-xs' +
                        (errorMessage ? ' text-red-500' : ' text-stone-700')
                    }
                >
                    {title}
                </p>
            )}
            <div className="relative w-full">
                <CurrencyFormat
                    aria-labelledby="currencyLabel"
                    pattern="[0-9]*"
                    inputMode="numeric"
                    value={value}
                    tabIndex={tabIndex}
                    id={id}
                    onValueChange={onValueChange}
                    onFocus={onFocus}
                    onClick={onClick}
                    className={
                        'h-12 w-full rounded border-stone-300 bg-none p-4 indent-6 text-base text-stone-700' +
                        (errorMessage
                            ? ' outline outline-red-500'
                            : ' border-[1px]')
                    }
                    thousandSeparator={true}
                    prefix={''}
                    onBlur={onBlur}
                />
                <BsCurrencyDollar className="absolute left-3 top-[.9375rem] text-xl text-stone-700" />
            </div>
            {errorMessage ? (
                <p className="mt-1 text-left text-xs text-red-500">
                    {errorMessage}
                </p>
            ) : null}
        </div>
    );
};

export default CurrencyInput;
