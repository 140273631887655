import React, { useState } from 'react';

type QueryState = {
    price: Array<number>;
    mileage?: Array<number>;
    lat?: number;
    long?: number;
    zip?: string;
    buyers?: Array<string>;
    start: number;
    size: number;
};

const defaultQueryState: QueryState = {
    price: [5000, 28500],
    mileage: [0, 60000],
    lat: undefined,
    long: undefined,
    zip: undefined,
    buyers: [
        '0000-1005',
        '0000-1230',
        '0000-2067',
        '0000-2120',
        '0000-2360',
        '0000-2674',
        '0000-2896',
        '0000-3002',
        '0000-3076',
        '0000-3236',
        '0000-4212',
        '0000-4262',
        '0000-4268',
        '0000-4271',
        '0000-4277',
        '0000-4461',
        '0000-4751',
    ],
    start: 0,
    size: 50,
};

export type UsedVehicleState = {
    city: string | undefined;
    state: string | undefined;
    bodyCounts: any;
    vehicleInventory: Array<object>;
    selectedKeys: Array<string>;
    excludeKeys: Array<string>;
    selectedInventory: Array<object>;
    selectedBodyTypes: Array<string>;
    selectedBodyTypeValues: Array<string>;
    searchQueryParams: QueryState;
};

interface IUsedVehicleContext {
    usedVehicleState: UsedVehicleState;
    updateUsedVehicleState: (usedVehicleState: UsedVehicleState) => void;
}

const defaultState: IUsedVehicleContext = {
    usedVehicleState: {
        city: undefined,
        state: undefined,
        bodyCounts: undefined,
        vehicleInventory: [],
        selectedKeys: [],
        excludeKeys: [],
        selectedInventory: [],
        selectedBodyTypes: [],
        selectedBodyTypeValues: [],
        searchQueryParams: defaultQueryState,
    },
    updateUsedVehicleState: (usedVehicleState: UsedVehicleState) => {},
};

export const UsedVehicleContext =
    React.createContext<IUsedVehicleContext>(defaultState);

export const UsedVehicleContextProvider = (props: { children: any }) => {
    const [state, setState] = useState(defaultState.usedVehicleState);

    const updateState = (state: UsedVehicleState) => setState(state);

    return (
        <UsedVehicleContext.Provider
            value={{
                usedVehicleState: state,
                updateUsedVehicleState: updateState,
            }}
        >
            {props.children}
        </UsedVehicleContext.Provider>
    );
};
