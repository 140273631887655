import { Dialog } from '@headlessui/react';
import { GrClose } from 'react-icons/gr';
import Button from '../Button';
import Input from '../Input';
import { useContext, useState } from 'react';
import { z } from 'zod';
import { useValidateForm } from '../../hooks/useValidateForm';
import { ImSpinner2 } from 'react-icons/im';
import { UsedVehicleContext } from '../../context/used-vehicle-context';
import {
    FormState,
    leadPost,
} from '../../pages/SearchUsedVehicles/SearchUsedVehiclesScripts';
import ThankYou from '../ThankYou';
import { itFormSubmitClick } from '../../helpers/harvester-events';
import { Context as HarvesterContext } from '../../helpers/harvester/react/Provider';
import FormSelect from '../FormSelect';
import Chip from '../Chip';

type FormDialogProps = {
    variant: string;
    isOpen: boolean;
    closeForm: () => void;
    closeCart: () => void;
};
const FormDialog: React.FC<FormDialogProps> = (props: FormDialogProps) => {
    //Context Vars
    const { harvester } = useContext(HarvesterContext);
    const { usedVehicleState, updateUsedVehicleState } =
        useContext(UsedVehicleContext);
    const { selectedInventory } = usedVehicleState;
    //State Vars
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isSuccess, setIsSuccess] = useState<boolean>(false);
    const [formState, setFormState] = useState<FormState>({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
    });
    const [commentState, setCommentState] = useState<string>(
        'Consumer is looking for the best pricing available on this vehicle.'
    );
    const [submitted, setSubmitted] = useState<boolean>(false);
    const [errorState, setErrorState] = useState({ valid: false });
    const [errorDisplayState, setErrorDisplayState] = useState({
        valid: false,
        errors: {},
    });

    //Start Comment Handlers
    const commentOptions = [
        {
            value: 'Consumer is looking for the best pricing available on this vehicle.',
            valueDisplay: 'Getting Payment Info',
        },
        {
            value: 'Consumer would like more information on this vehicle.',
            valueDisplay: 'More Information',
        },
        {
            value: 'Consumer would like to know if this vehicle is still available.',
            valueDisplay: 'Checking Availability',
        },
        {
            value: 'Consumer would like to get pre-qualified for this vehicle.',
            valueDisplay: 'Getting Prequalified',
        },
    ];

    const commentChangeHandler = (e: any) => {
        setCommentState(e.value);
    };

    const submitButtonText = () => {
        let text: string;
        switch (commentState) {
            case 'Consumer is looking for the best pricing available on this vehicle.':
                text = 'GET PAYMENT INFO';
                break;
            case 'Consumer would like more information on this vehicle.':
                text = 'GET MORE INFORMATION';
                break;
            case 'Consumer would like to know if this vehicle is still available.':
                text = 'CHECK AVAILABILITY';
                break;
            case 'Consumer would like to get pre-qualified for this vehicle.':
                text = 'GET PREQUALIFIED';
                break;
            default:
                text = 'GET BEST PRICING';
                break;
        }
        return text;
    };
    //End Comment Handlers

    //Start Input Change Handlers
    const nameChangeHandler = (type: 'first' | 'last', e: any) => {
        const value = e.value;
        const regex = /^[^0-9]*$/;
        const regexCheck = value.match(regex) ? true : false;
        if (regexCheck) {
            type === 'first' &&
                setFormState({ ...formState, firstName: value });
            type === 'last' && setFormState({ ...formState, lastName: value });
        }
    };
    //End Input Change Handlers

    //Start Form Validation
    const formSchema = z.object({
        firstName: z
            .string({
                required_error: ' is required',
            })
            .min(1, 'is required'),
        lastName: z
            .string({
                required_error: 'is required',
            })
            .min(1, 'is required'),
        email: z
            .string({
                required_error: 'Email is required',
            })
            .email('is invalid'),
        phone: z
            .string({
                required_error: 'is required',
            })
            .min(10, 'Phone number is required')
            .max(10, 'Phone number is required'),
    });

    const submit = () => {
        itFormSubmitClick(harvester);
        if (errorState.valid || errorDisplayState.valid) {
            completeForm();
        } else {
            setSubmitted(true);
        }
    };

    const completeForm = async () => {
        setIsLoading(true);
        await leadPost(usedVehicleState, formState, commentState);
        setIsSuccess(true);
        setIsLoading(false);
        // props.closeForm();
    };

    const closeSuccess = () => {
        setIsSuccess(false);
    };

    useValidateForm(
        formSchema,
        formState,
        setErrorState,
        setErrorDisplayState,
        submitted
    );
    //End Form Validation

    // A/B Test Selector
    const abTestSelector = () => {
        switch (props.variant) {
            case 'A':
                return (
                    <FormSelect
                        title="I'm Interested In:"
                        options={commentOptions}
                        value={commentState}
                        className="h-[2.625rem]"
                        onChange={(e) => commentChangeHandler(e.currentTarget)}
                    />
                );
                break;
            case 'B':
                return (
                    <div className="max-w-xs">
                        <p className="mb-1 text-xs font-normal text-stone-700">
                            I'm Interested In:
                        </p>
                        <div className="flex flex-wrap items-start gap-x-1 gap-y-1">
                            {commentOptions.map((e, key) => {
                                return (
                                    <Chip
                                        key={key}
                                        label={e.valueDisplay}
                                        selected={commentState === e.value}
                                        value={e.value}
                                        onClick={(e) =>
                                            commentChangeHandler(
                                                e.currentTarget
                                            )
                                        }
                                    />
                                );
                            })}
                        </div>
                    </div>
                );
                break;
            default:
                return (
                    <FormSelect
                        title="I'm Interested In:"
                        options={commentOptions}
                        value={commentState}
                        className="h-[2.625rem]"
                        onChange={(e) => commentChangeHandler(e.currentTarget)}
                    />
                );
                break;
        }
    };

    return (
        <Dialog
            as="div"
            open={props.isOpen}
            onClose={props.closeForm}
            className="fixed top-0 z-[114] box-border flex h-screen w-screen justify-center overflow-x-clip overflow-y-scroll bg-black/80 p-6"
        >
            <Dialog.Panel
                as="div"
                className={
                    'relative my-auto flex w-full min-w-[17rem] flex-col items-center justify-center rounded bg-white p-6 md:max-w-[32.375rem] md:items-start' +
                    (isLoading ? ' ' + 'h-full' : ' ' + 'h-fit')
                }
            >
                {isLoading ? (
                    <div className="flex h-full w-full flex-col items-center justify-center">
                        <ImSpinner2 className="animate-spin text-9xl text-blue-500" />
                    </div>
                ) : isSuccess ? (
                    <ThankYou
                        closeForm={props.closeForm}
                        closeCart={props.closeCart}
                        success={() => closeSuccess()}
                    />
                ) : (
                    <>
                        <div className="mb-1 flex w-full items-center justify-between">
                            <h3 className="text-xl font-bold text-stone-700">
                                Almost Done!
                            </h3>
                            {/* <p className="ml-4 text-stone-500 max-lg:hidden lg:block">
                                Complete the form below to get payment
                                information for your selected vehicles:
                            </p> */}
                            <GrClose
                                onClick={props.closeForm}
                                className="lg:ml-auto"
                            />
                        </div>
                        <p className="text-stone-500">
                            Complete the form below to get payment information
                            for your selected vehicles:
                        </p>
                        <div className="my-6 flex w-full gap-y-3 max-md:flex-col md:flex-wrap md:gap-x-4">
                            {abTestSelector()}
                            <Input
                                type="firstName"
                                value={formState.firstName}
                                onChange={(e) =>
                                    nameChangeHandler('first', e.currentTarget)
                                }
                                errorMessage={
                                    (errorDisplayState.errors as any).firstName
                                }
                            />
                            <Input
                                type="lastName"
                                value={formState.lastName}
                                onChange={(e) =>
                                    nameChangeHandler('last', e.currentTarget)
                                }
                                errorMessage={
                                    (errorDisplayState.errors as any).lastName
                                }
                            />
                            <Input
                                type="email"
                                value={formState.email}
                                onChange={(e) =>
                                    setFormState({
                                        ...formState,
                                        email: (e.target as any).value,
                                    })
                                }
                                errorMessage={
                                    (errorDisplayState.errors as any).email
                                }
                            />
                            <Input
                                type="phone"
                                value={formState.phone}
                                onChange={(e) =>
                                    setFormState({
                                        ...formState,
                                        phone: (e as any).value,
                                    })
                                }
                                errorMessage={
                                    (errorDisplayState.errors as any).phone
                                }
                            />
                            {/* <Button
                                className="h-[2.625rem] px-3 max-xl:hidden"
                                textClass="text-sm font-bold whitespace-nowrap"
                                onClick={() => submit()}
                            >
                                GET PAYMENT INFO*
                            </Button> */}
                        </div>
                        <Button
                            className="mb-6 h-9 max-md:w-full md:px-3"
                            textClass="text-sm font-bold"
                            onClick={() => submit()}
                        >
                            {submitButtonText()}*
                        </Button>
                        <p className="text-xs text-stone-500">
                            *Car Deal Finder, the brands, and/or the dealers
                            that I select on this form may contact me on any
                            number I provide, whether a mobile phone number or a
                            land line number, using automated telephone dialing
                            systems, text and/or pre-recorded messages for
                            telemarketing purposes. By providing a telephone
                            number and completing a quote form, I am expressly
                            consenting to these methods of contact. I understand
                            that calls and messages to any mobile phone number
                            may incur access fees from my cellular provider. I
                            understand that consent is not required as a
                            condition of these services.
                        </p>
                    </>
                )}
            </Dialog.Panel>
        </Dialog>
    );
};

export default FormDialog;
