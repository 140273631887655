import { useLayoutEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const useScrollToTop = () => {
    const navigate = useNavigate();

    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    }, [navigate]);
};

export default useScrollToTop;
