import { string } from 'zod';

type ChipProps = {
    label: string;
    selected: boolean;
    value: string;
    onClick: (e: any) => void;
};

const Chip: React.FC<ChipProps> = (props: ChipProps) => {
    return (
        <button
            className={
                'cursor-pointer rounded-full border-[1px] border-blue-500 px-2 py-1 text-xs' +
                (props.selected ? ' bg-blue-500 text-white' : ' text-blue-500')
            }
            value={props.value}
            onClick={props.onClick}
        >
            {props.label}
        </button>
    );
};

export default Chip;
