import { Dialog } from '@headlessui/react';
import { useContext } from 'react';
import { GrClose } from 'react-icons/gr';
import { UsedVehicleContext } from '../../context/used-vehicle-context';
import VehicleDetailCard from '../VehicleDetailCard';
import SrpFooter from '../SrpFooter';

type CartDialogProps = {
    isOpen: boolean;
    closeCart: () => void;
    formOpen: () => void;
};

const CartDialog: React.FC<CartDialogProps> = (props: CartDialogProps) => {
    const { usedVehicleState, updateUsedVehicleState } =
        useContext(UsedVehicleContext);
    const { selectedInventory } = usedVehicleState;

    const selectedVehicleCards = () => {
        const cards = selectedInventory.map((vehicle: any) => {
            const {
                vehicleId,
                vehiclePicture,
                vehicleYear,
                vehicleMake,
                vehicleModel,
                vehiclePrice,
                vehicleMileage,
                vehicleLocation,
                distance,
                vehicleVin,
                checkKey,
            } = vehicle;
            return (
                <VehicleDetailCard
                    key={vehicleId}
                    vehiclePicture={vehiclePicture}
                    vehicleYear={vehicleYear}
                    vehicleMake={vehicleMake}
                    vehicleModel={vehicleModel}
                    vehiclePrice={vehiclePrice}
                    vehicleMileage={vehicleMileage}
                    vehicleLocation={vehicleLocation}
                    distance={distance}
                    vehicleVin={vehicleVin}
                    checkKey={checkKey}
                />
            );
        });
        return cards;
    };

    return (
        <Dialog
            as="div"
            open={props.isOpen}
            onClose={props.closeCart}
            className="fixed top-0 z-[113] h-screen w-full overflow-y-scroll bg-white"
            onClick={(e) => e.stopPropagation()}
        >
            <Dialog.Panel
                as="div"
                className="relative h-full w-full"
                onClick={(e) => e.stopPropagation()}
            >
                <div className="p-4">
                    <div className="mb-6 flex items-center justify-between">
                        <h3 className="text-base font-bold text-neutral-800">
                            Selected Vehicles
                        </h3>
                        <GrClose onClick={props.closeCart} />
                    </div>
                    <div className="flex w-full gap-y-6 last:mx-auto max-md:flex-col max-md:items-center md:flex-wrap md:justify-center md:gap-x-6">
                        {selectedInventory.length > 0 ? (
                            selectedVehicleCards()
                        ) : (
                            <p>No vehicles selected</p>
                        )}
                    </div>
                </div>
                <SrpFooter formOpen={props.formOpen} />
            </Dialog.Panel>
        </Dialog>
    );
};

export default CartDialog;
