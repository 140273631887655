import React from 'react';
import { Helmet } from 'react-helmet';

type SimpleLayoutProps = {
    children?: JSX.Element | JSX.Element[];
    pageTitle?: string;
};

const SimpleLayout: React.FC<SimpleLayoutProps> = ({ children, pageTitle }) => {
    return (
        <>
            <Helmet>
                <title>
                    {pageTitle ? `${pageTitle}` : 'Car Payment Calculator'}
                </title>
            </Helmet>
            <div className="flex min-h-screen flex-col bg-stone-200">
                <main className="mx-auto w-full max-w-[100vw]">{children}</main>
            </div>
        </>
    );
};

export default SimpleLayout;
