import React from 'react';
import { Harvester } from '../Harvester.js';
import { BatchHarvester } from '../BatchHarvester.js';

export const Context = React.createContext();

export const Provider = ({ config, batching = true, children }) => {
    const HarvesterClass = batching ? BatchHarvester : Harvester;
    const harvester = new HarvesterClass(config);

    return (
        <Context.Provider value={{ harvester }}>{children}</Context.Provider>
    );
};

export default {
    Harvester,
    BatchHarvester,
    Context,
    Provider,
};
