import React from 'react';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import CarpaymentLogoInvert from '../../images/easy-peasy-transparent-invert.svg';

const Footer: React.FC = () => {
    return (
        <nav className="w-full text-xs">
            <ul className="flex gap-1 max-lg:flex-col lg:gap-6">
                <li>
                    <Link tabIndex={4} to="/terms-of-use">
                        Terms of Use
                    </Link>
                </li>
                <li>
                    <Link tabIndex={5} to="/privacy-policy">
                        Privacy Policy
                    </Link>
                </li>
                <li>
                    <a
                        tabIndex={6}
                        href="https://www.rydeshopper.com/app/cardealfinder/Unsubscribe"
                    >
                        Unsubscribe
                    </a>
                </li>
                <li>
                    <HashLink tabIndex={7} to="/privacy-policy#data-rights">
                        Do Not Sell My Personal Information
                    </HashLink>
                </li>
            </ul>
        </nav>
    );
};

export default Footer;
