import { v4 as uuid } from 'uuid';
import { hash, serialize } from './util.js';
import { Module } from './Module.js';

export class Harvester {
    constructor({ modules = {}, globals = {} } = {}) {
        this.id = uuid();
        this.modules = new Map();
        this.globals = globals;

        Object.entries(modules).forEach(([name, opts]) =>
            this.addModule(name, opts)
        );
    }

    addModule(name, opts = {}) {
        this.modules.set(name, new Module({ name, ...opts }));
        return this;
    }

    removeModule(name) {
        this.modules.delete(name);
        return this;
    }

    getGlobals(name, opts = {}) {
        const module = this.modules.get(name) || {};

        return { ...this.globals, ...module.globals, properties: opts };
    }

    async process(event, properties, targetModule = null) {
        this.modules.forEach((module) => {
            if (targetModule && targetModule !== module.name) return;

            const globals = this.getGlobals(module.name, {
                hash: hash(serialize([event, properties])),
            });

            if (module.filter(event, properties, globals)) {
                module.handler(event, properties, globals, (...args) =>
                    this.exec(module.name, ...args)
                );
            }
        });
        return this;
    }

    async dispatch(event, properties = {}) {
        return this.process(event, properties);
    }

    async to(name, event, properties = {}) {
        return this.process(event, properties, name);
    }

    exec(name, method, ...args) {
        const module = this.modules.get(name);

        if (module && typeof module[method] === 'function') {
            const globals = this.getGlobals(module.name);

            return module[method](globals, ...args);
        }
    }
}

export default Harvester;
