import React, { FocusEventHandler, FormEventHandler } from 'react';

type FormSelectProps = {
    options: Array<{
        value: string;
        valueDisplay: string;
    }>;
    id?: string;
    type?: string;
    value?: any;
    title?: string;
    placeholder?: string;
    tabIndex?: number;
    errorMessage?: string;
    disabled?: boolean;
    className?: string;
    onChange?: FormEventHandler | undefined;
    onInput?: FormEventHandler | undefined;
    onBlur?: FocusEventHandler | undefined;
};

const FormSelect: React.FC<FormSelectProps> = ({
    title = '',
    options,
    errorMessage,
    tabIndex,
    className,
    ...inputProps
}) => {
    return (
        <div className="w-full">
            {title && (
                <p id="formSelectLabel" className="mb-1 text-xs text-stone-700">
                    {title}
                </p>
            )}
            <div className="relative w-full">
                <select
                    className={
                        'w-full rounded border-stone-300 px-3 text-base text-stone-700 focus-visible:outline-2 focus-visible:outline-blue-light' +
                        (className ? ' ' + className : '') +
                        (errorMessage
                            ? ' outline outline-red-500'
                            : ' border-[1px]')
                    }
                    aria-labelledby="formSelectLabel"
                    title={title}
                    tabIndex={tabIndex}
                    {...inputProps}
                >
                    {options.map((option, key) => (
                        <option key={key} value={option.value}>
                            {option.valueDisplay}
                        </option>
                    ))}
                </select>
            </div>
            {errorMessage ? (
                <p className="text-left text-sm text-red-500">
                    *{errorMessage}
                </p>
            ) : null}
        </div>
    );
};

export default FormSelect;
