import React from 'react';
import SimpleLayout from '../components/SimpleLayout';
import SimpleHeader from '../components/SimpleHeader';
import ScrollToTop from '../components/ScrollToTop';

const PrivacyPolicy: React.FC = ({ location }: any) => {
    return (
        <SimpleLayout pageTitle="Privacy Policy">
            <SimpleHeader />
            <ScrollToTop />
            <div className="mt-4 font-manrope">
                <div className="mx-auto w-full px-3 md:max-w-[720px] xl:max-w-7xl">
                    <h4 className="mb-2 text-xl font-medium lg:text-2xl">
                        Privacy Statement
                    </h4>
                    <h4 className="mb-2 text-xl font-medium lg:text-2xl">
                        Effective Date:{' '}
                        <span className="font-normal">December 20, 2015</span>
                    </h4>
                    <h4 className="mb-2 text-xl font-medium lg:text-2xl">
                        Revised:{' '}
                        <span className="font-normal">January 1, 2022</span>
                    </h4>
                    <br></br>
                    <h3 className="mb-2 text-2xl font-medium lg:text-[1.75rem]">
                        Welcome
                    </h3>
                    <br></br>
                    <p className="mb-4">
                        Thank you for your interest in this auto financing
                        online pre-qualification site (the “Site”), which is
                        owned and operated by Detroit Trading Services, LLC
                        (“Detroit Trading”). We encourage you to read the
                        following Privacy Statement, which outlines how the Site
                        and Detroit Trading collects, uses and maintains
                        personal information from Site users. This Privacy
                        Statement also outlines your rights as a consumer.
                    </p>
                    <p className="mb-4">
                        Detroit Trading hosts and maintains this Site to help
                        you get pre-qualified for automotive financing online.
                        When used in these Terms, Detroit Trading shall include
                        any parent companies, affiliates, directors, officers,
                        and employees.
                    </p>
                    <p className="mb-4">
                        The Site provides users with various services, including
                        an online platform that enables users to get
                        pre-qualified online for the finance of a new and/or
                        used motor vehicle (the “Services”). By using the Site
                        or by providing personal information to us, you agree to
                        the terms set forth below (the “Terms”). We respect your
                        privacy when you use our Site.
                    </p>
                    <br></br>
                    <h3 className="mb-2 text-2xl font-medium lg:text-[1.75rem]">
                        What This Privacy Statement Covers and Does Not Cover
                    </h3>
                    <br></br>
                    <p className="mb-4">
                        This Privacy Statement covers Detroit Trading’s use of
                        the personal information you submit. Personal
                        information is information that identifies, relates to,
                        describes, is reasonably capable of being associated
                        with, or could reasonably be linked, directly or
                        indirectly, with a particular consumer or household, and
                        includes your first and last name, home mailing address,
                        email address, phone number, information required to
                        pull credit scores and/or verify income and/or any
                        additional information that is not otherwise publicly
                        available (“Personal Information”).
                    </p>
                    <p className="mb-4">
                        This policy does not cover the practices of companies
                        that Detroit Trading does not own or control, or to
                        people that Detroit Trading does not employ or manage,
                        or to information collected by means other than those
                        provided on this Site.
                    </p>
                    <br></br>
                    <h3 className="mb-2 text-2xl font-medium lg:text-[1.75rem]">
                        Information Detroit Trading Collects About You
                    </h3>
                    <br></br>
                    <p className="mb-4">
                        Detroit Trading collects two (2) broad categories of
                        Personal Information from individuals who use its Site:
                    </p>
                    <ul className="mb-4 list-disc pl-8">
                        <li className="mb-4">
                            <b>
                                Personal Information Collected to Pre-Qualify
                                You for an Automotive Loan on Behalf of our
                                Clients:
                            </b>{' '}
                            We collect information on behalf of vehicle dealers,
                            auto finance companies, lenders, original equipment
                            manufacturers and/or their related service providers
                            and their representatives and/or affiliates (each a
                            “Client”, collectively, “Clients”). For example, the
                            information you provide to us when filling out a
                            Form on the Site is collected by us on behalf of our
                            Clients. In this context, Detroit Trading is acting
                            as a service provider and the Personal Information
                            captured is subject to the privacy notices of the
                            Client on whose behalf it is collected. You can
                            access a Client’s privacy notice by visiting their
                            site, scrolling to the footer of the page and
                            clicking on the applicable link.
                        </li>
                        <li className="mb-4">
                            <b>
                                Personal Information Collected to Operate our
                                Services:
                            </b>{' '}
                            In order to operate and improve our services, we
                            capture Personal Information automatically from the
                            users when they interact with the Site. We also
                            obtain information from our affiliates, public
                            sources, or other sources.
                        </li>
                    </ul>
                    <p className="mb-4">
                        <b>
                            Personal Information Collected on Behalf of our
                            Clients
                        </b>
                        . Detroit Trading collects Personal Information on
                        behalf of our Clients and acting as a service provider
                        when you:
                    </p>

                    <ul className="mb-4 list-disc pl-8">
                        <p className="mb-4">
                            <b>{'>'}</b>{' '}
                            <b>Use Our Quote Form to Pre-Qualify</b>. For
                            Automotive Financing. When you use our Site to
                            pre-qualify for automotive financing, we ask for
                            Personal Information such as your first and last
                            name, home mailing address (including street number,
                            street name, city, state and zip code) email
                            address, phone number, time at address, whether you
                            rent or own, monthly housing payment, social
                            security number, employment and income history,
                            home/mobile/work phone number(s) and/or related
                            information to help us and our partners verify
                            income and/or credit worthiness.
                        </p>
                        <p className="mb-4">
                            <b>{'>'}</b>{' '}
                            <b>
                                Use Our Toll Free Number to Request Additional
                                Information
                            </b>
                            . When you call us, we, Detroit Trading, or an
                            authorized service partner acting on our behalf, may
                            request and collect additional Personal Information
                            to learn more about you, your needs and, if you
                            expressly consent to further contact, we will place
                            you in contact with up to five (5) automotive
                            dealers and/or partner affiliates who will contact
                            you about your request. We, or an authorized service
                            partner acting on our behalf, may also collect other
                            information about the call, such as call duration
                            and call disposition.
                        </p>
                    </ul>
                    <p className="mb-4">
                        <b>
                            Personal Information Collected to Operate our
                            Services:
                        </b>{' '}
                        In addition to collecting Personal Information on behalf
                        of our clients, Detroit Trading collects Personal
                        Information as follows
                    </p>
                    <ul className="mb-4 list-disc pl-8">
                        <p className="mb-4">
                            <b>{'>'}</b> <b>Analytical Information</b>. Detroit
                            Trading uses automated methods to collect and
                            aggregate information regarding general use of this
                            Site. This is collected and stored in the form of
                            user analytics, and it is used to help us improve
                            user experience and the relevance of content that we
                            provide and deliver. This analytical information may
                            include, but is not limited to:
                        </p>
                        <p className="mb-4">
                            {' '}
                            <b>{'>'}</b> Your IP address and the type of browser
                            and operating system used.{' '}
                        </p>
                        <p className="mb-4">
                            {' '}
                            <b>{'>'}</b> The date and time you accessed the Site
                            and the pages you visited.
                        </p>
                        <p className="mb-4">
                            {' '}
                            <b>{'>'}</b> Whether or not you linked from or to
                            another website.{' '}
                        </p>
                        <p className="mb-4">
                            {' '}
                            <b>{'>'}</b> Whether or not you open any email
                            generated from the Site, and if so, the links that
                            you click within the email.
                        </p>
                        <p className="mb-4">
                            {' '}
                            <b>{'>'}</b> Your ISP or mobile carrier, and the
                            type of handheld or mobile device that you used.
                        </p>
                        <p className="mb-4">
                            <b>{'>'}</b>{' '}
                            <b>
                                Cookies, Local Shared Objects, Web Beacons and
                                other Data Technologies
                            </b>
                            . Detroit Trading automatically receives and records
                            information on our server logs from your browser.
                            This information may include, but is not limited to
                        </p>
                        <ul className="mb-4 list-disc pl-8">
                            <li className="mb-4">
                                Cookies. Cookies are small identifiers sent from
                                a web server and stored on your device for the
                                purpose of identifying your browser and/or
                                storing information or settings in your browser.{' '}
                            </li>
                            <li className="mb-4">
                                Local Shared Objects. Local shared objects are
                                used to store your preferences or display
                                content based on what you have viewed on other
                                websites. They are sometimes known as “Flash
                                Cookies”, and are used to personalize your
                                viewing experience.
                            </li>
                            <li className="mb-4">
                                Web Beacons. Web beacons link web pages to web
                                servers and their cookies.{' '}
                            </li>
                            <li className="mb-4">
                                Data Technologies. Any other technology that may
                                be developed to track and analyze online
                                browsing patterns and provide more customized
                                browsing experiences on the Site.
                            </li>
                        </ul>
                        <p className="mb-4">
                            <b>{'>'}</b> <b>Publicly Posted Information</b>.
                            Detroit Trading may collect information that you
                            make available to us and others via social media
                            networks, forums, blogs, list serves, chat rooms or
                            similar functionality. This information is public
                            information that we or others may share or use in
                            accordance with the law.
                        </p>
                    </ul>
                    <br></br>
                    <p className="mb-4">
                        <b>Consent Capture.</b> Detroit Trading uses
                        ActiveProspect’s TrustedForm Script to independently
                        document users’ consent to be contacted. The TrustedForm
                        Script is embedded on our website and collects the
                        following information when you interact with the page(s)
                        where the script is present: page URL, mouse movements
                        and clicks, contact information inputted by the user, a
                        snapshot of the page, including IP address of the user’s
                        computer, time on the page, date and time that the
                        TrustedForm Script was loaded, as well as the date and
                        time of the various user interactions with the page, and
                        HTTP headers from the user’s browser. For more
                        information about ActiveProspect’s data use and
                        collection policies and practices, please review the
                        ActiveProspect Privacy Policy, located at{' '}
                        <a href="https://activeprospect.com/privacy-policy/">
                            https://activeprospect.com/privacy-policy/
                        </a>
                        .
                    </p>
                    <br></br>
                    <h3 className="mb-2 text-2xl font-medium lg:text-[1.75rem]">
                        How Detroit Trading Uses and Shares the Personal
                        Information It Collects
                    </h3>
                    <br></br>
                    <p className="mb-4">
                        When Detroit Trading collects Personal Information on
                        behalf of a Client, the Personal Information collected
                        is provided to that Client and the Client uses the
                        information to provide you with the products and
                        services you requested. The Client may follow up with
                        you about the nature of your request or use your
                        Personal Information to communicate with you about your
                        request in accordance to their respective privacy
                        statements and/or privacy policies, which you can access
                        via visiting the applicable Client’s website, scrolling
                        to the footer and clicking on the applicable link. As
                        service providers, we provide this information to our
                        Clients pursuant to a written contract, in response to
                        the business purpose of responding to your request for
                        more information about the purchase, finance and/or
                        service of a motor vehicle and in return for
                        compensation provided by our Clients or otherwise. After
                        you have used our Site and consented to receiving
                        additional information about the purchase and/or finance
                        of an automobile, we may also contact you with a
                        manually dialed phone call and/or an additional
                        communication to request and/or share additional
                        information about your request.
                    </p>
                    <p className="mb-4">
                        In regards to the Personal Information collected to
                        operate our services, we may use and share it in the
                        following ways:
                    </p>
                    <ol className="list-decimal pl-8">
                        <li className="mb-4">
                            <b>For Everyday Business</b>. Detroit Trading may
                            share Personal Information collected to operate our
                            services with group companies and entities that
                            provide services to us for everyday business
                            purposes such as managing your user account (if
                            any), diagnosing technical issues, improving
                            financial performance, improving user experience and
                            evaluating our business.
                        </li>
                        <li className="mb-4">
                            <b>In Response to Legal Action</b>. Detroit Trading
                            will share your Personal Information to respond to
                            subpoenas, court orders, or legal process, or to
                            establish or exercise our legal rights or defend
                            against legal claims.
                        </li>
                        <li className="mb-4">
                            <b>To Prevent Fraud or Illegal Action</b>. Detroit
                            Trading will share your Personal Information when we
                            believe it is necessary in order to investigate,
                            prevent, or take action regarding illegal
                            activities, suspected fraud, situations involving
                            potential threats to the physical safety of any
                            person, violations of the Site’s Terms or Use, or as
                            otherwise required by law.
                        </li>
                        <li className="mb-4">
                            <b>In the Context of a Merger or Acquisition:</b> If
                            we agree to sell, transfer or merge all or a portion
                            of our business or assets, or in preparation for
                            such operation under a non-disclosure agreement, in
                            which case we will endeavor to ensure that the
                            transferee agrees to use the Personal Information in
                            a manner consistent with this notice.
                        </li>
                        <li className="mb-4">
                            <b>Combined with Other Sources</b>. We may combine
                            Personal Information that you provide to us with
                            information we receive from our affiliates and other
                            sources, as well as with other information that is
                            automatically collected. The combined information
                            may include information about vehicle history, your
                            interests and activities, additional demographic
                            data, your use of the Site, your use of other
                            websites, devices and mobile applications and
                            information from our affiliates and other sources,
                            and may be used to verify the accuracy of the data
                            that you submit and/or provide us and our partners
                            with additional insight about your preferences and
                            the nature of your online request.
                        </li>
                    </ol>
                    <p className="mb-4">
                        <b>Transferability Notice</b>. By providing us with your
                        Personal Information, you expressly consent to the
                        onward transfer of this Personal Information to our
                        Clients so that they can follow up to your request. You
                        understand that your Personal Information will be used
                        in accordance to the terms of the Privacy Statements
                        and/or Privacy Policies of those Clients.
                    </p>
                    <p className="mb-4">
                        <b>Verification of Vendors and Partners</b>. As noted
                        above, we may share your Personal Information – whether
                        for compensation or otherwise – with Clients with whom
                        we have a contractual relationship as a service provider
                        and who wish to do business, or attempt to do business
                        with you, so that they can follow up to your request
                        regarding the purchase and/or finance of a motor
                        vehicle. While we use good faith efforts to verify that
                        such entities will promote products and services in
                        response to the requests made by you while using our
                        Site, we cannot, and do not, endorse them unless we
                        expressly state otherwise.
                    </p>
                    <p className="mb-4">
                        <b>Your Express Consent</b>. By using the services on
                        this Site, you expressly consent to give to us and to
                        our Clients the right to contact you and to attempt to
                        sell, finance and/or service a motor vehicle. Such
                        contact may be made to the number that you provide,
                        whether land line number or mobile phone number, using
                        automated telephone dialing systems, text and/or
                        pre-recorded messages. By submitting a quote form, you
                        expressly consent to these methods of contact and the
                        onward transfer of your consent to the parties who will
                        be contacting you about your request. You also expressly
                        consent to having a credit report pulled on you using
                        the information you provided in the online quote form.
                        Finally, you expressly consent to the packaging of your
                        information and transaction of your information – for
                        compensation or otherwise – to a Client of Detroit
                        Trading.
                    </p>
                    <p className="mb-4">
                        Your use of this Site establishes a business or other
                        applicable relationship with us for purposes of
                        CAN-SPAM, the Telephone Consumer Protection Act and all
                        other applicable law that addresses unsolicited
                        commercial communications.
                    </p>
                    <p className="mb-4">
                        If you wish us to learn more about the data that we
                        collect, opt-out from communications and/or refrain from
                        providing your Personal Information in this manner,
                        please see the opt-out information and contact
                        information provided below.
                    </p>
                    <br></br>
                    <h3 className="mb-2 text-2xl font-medium lg:text-[1.75rem]">
                        Our Use of Cookies and Related Technology
                    </h3>
                    <br></br>
                    <p className="mb-4">
                        As disclosed earlier, Detroit Trading may set and access
                        cookies on your computer. We offer certain features that
                        are only available through the use of cookies. We use
                        cookies to help identify you, maintain your search
                        results and introduce you to new products and/or
                        services. You are always free to decline our cookies, if
                        your browser permits, though doing so may interfere with
                        your use of the Site.
                    </p>
                    <p className="mb-4">
                        Detroit Trading may engage advertising agencies and/or
                        direct clients to provide advertisements on the Site.
                        Those entities might use cookies and other tracking
                        devices to collect information about your online
                        activities over time and across different websites and
                        services. That information may include: browser, IP
                        address, page(s) visited, time, and date. This
                        information is used to evaluate advertising activities
                        and/or introduce related products, services and/or
                        offers from these entities or their clients.
                    </p>
                    <p className="mb-4">
                        You might be able to exercise choices regarding such
                        companies using third-party tools. Information about
                        those third-party tools is available at{' '}
                        <a
                            className="max-sm:text-xs"
                            href="http://www.networkadvertising.org/choices/"
                        >
                            http://www.networkadvertising.org/choices/
                        </a>{' '}
                        and{' '}
                        <a
                            className="max-sm:text-xs"
                            href="http://www.aboutads.info/choices/"
                        >
                            {' '}
                            http://www.aboutads.info/choices/
                        </a>
                        .
                    </p>
                    <p className="mb-4">
                        Additionally, most web browsers include a “do not track”
                        setting. There is a lack of certainty among companies on
                        the web about the impact of the setting on the
                        information collected. If you enable that setting, it
                        will not change what information is exchanged with your
                        computer, or how that information is used, when you
                        visit our website(s).
                    </p>
                    <br></br>
                    <h3 className="mb-2 text-2xl font-medium lg:text-[1.75rem]">
                        Data Security
                    </h3>
                    <br></br>
                    <p className="mb-4">
                        Detroit Trading uses commercially reasonable
                        administrative, technical, personnel, and physical
                        security measures designed to safeguard information
                        about you in our possession against loss, theft and
                        unauthorized use, disclosure or modification. Despite
                        these measures, we cannot guarantee perfect security of
                        networks, servers and databases we operate or that are
                        operated on our behalf.
                    </p>
                    <br></br>
                    <h3 id="data-rights">Your Data Rights</h3>
                    <br></br>
                    <p className="mb-4">
                        With regards to{' '}
                        <b>
                            Personal Information Collected on Behalf of our
                            Clients
                        </b>
                        , you may have rights as stated in the respective
                        privacy statements and/or privacy policies of the
                        Client, which you can exercise following the steps
                        outlined in their privacy notices which are accessible
                        via visiting the applicable Client’s website, scrolling
                        to the footer and clicking on the applicable link.
                    </p>
                    <p className="mb-4">
                        With regards to{' '}
                        <b>
                            Personal Information Collected to Operate our
                            Services
                        </b>{' '}
                        Detroit Trading provides Site users the rights described
                        below:
                    </p>
                    <ol className="list-decimal pl-8">
                        <li className="mb-4">
                            <b>
                                Right to Request Access to the Information
                                Collected and Shared
                            </b>
                            . You have a right to request disclosure of the
                            categories and specific pieces of Personal
                            Information we have collected about you. Please see
                            below for the methods for submitting such a request.
                        </li>
                        <li className="mb-4">
                            <b>Right to Delete</b>. You have a right to request
                            us to delete the Personal Information we have
                            collected about you. Please see below for the
                            methods for submitting such a request.
                        </li>
                        <li className="mb-4">
                            <b>Right to Opt Out from Communications</b>. You
                            have the right, at any time, to opt out of receiving
                            communications from us. Please see below for the
                            methods for submitting such a request
                        </li>
                        <li className="mb-4">
                            <b>Right to Opt Out from Information Being Sold</b>.
                            You have the right, at any time, to opt out of the
                            sale of your Personal Information by one of our
                            Clients to a third party. Please see below for the
                            methods for submitting such a request, which we will
                            share with our Client so that they can process.
                        </li>
                        <li className="mb-4">
                            <b>
                                Right to Non Discrimination Related to
                                Exercising Your Rights
                            </b>
                            . We will not discriminate against you if you
                            exercise any of these rights.
                        </li>
                    </ol>
                    <p className="mb-4">Methods to Submit a Request:</p>
                    <ol className="list-decimal pl-8">
                        <li className="mb-4">
                            <b>Via Email:</b> To exercise any of the
                            aforementioned rights, please email us at:
                            info@carready.com, subject line “CarReady – Right to
                            Request”. In the body of your email, please clearly
                            state the right you would like to exercise (e.g.
                            Right to Request Information Collected and Shared,
                            Right to Request Information Sold, Right to Delete,
                            Right to Opt Out from Communications and/or Right to
                            Opt Out from Information Being Sold.”
                        </li>
                        <li className="mb-4">
                            <b>Via Phone:</b> To exercise any of the
                            aforementioned rights, please call us toll-free at
                            1-833-621-0227 and clearly indicate that you visited
                            CarReady.com and would like to exercise your data
                            rights. You will be asked what right you would like
                            to exercise. Be ready to clearly disclose the data
                            right you would like to exercise, e.g. Right to
                            Request Information Collected and Shared, Right to
                            Request Information Sold, Right to Delete, Right to
                            Opt Out from Communications and/or Right to Opt Out
                            from Information Being Sold.
                        </li>
                    </ol>
                    <p className="mb-4">
                        Detroit Trading will comply with such requests as soon
                        as is commercially practicable. This compliance may
                        involve batch processing and other processes that may
                        take ten (10) days or longer to process.
                    </p>
                    <p className="mb-4">
                        <b>Limitations</b>. Because your privacy is important to
                        us, Detroit Trading extends the rights described above
                        to all Site users regardless of whether they reside in a
                        State that mandates them or not.{' '}
                        <b>
                            Please note that your rights are subject to certain
                            conditions and exceptions
                        </b>
                        . For States that mandate and regulate these rights,
                        Detroit Trading will comply with applicable law which
                        provides for limitations (such as limitations under
                        California law to the right to know based on our
                        compliance with evidentiary privileges recognized under
                        California Law) and exceptions (such as exceptions to
                        the California right to erase under Cal. Civ. Code Sec.
                        1798.105(d).) For States where these rights are not
                        mandated under applicable law, Detroit Trading will
                        follow its own internal policies and procedures to
                        consider and grant the rights and establish appropriate
                        limitations.
                    </p>
                    <p className="mb-4">
                        Please also note that, on a case by case basis and in
                        compliance with the California Consumer Privacy Act and
                        any other state or federal statutes governing the
                        collection and storage of Personal Information, we may
                        continue to keep your Personal Information and any
                        related information to the extent necessary to respond
                        to any request involving your data rights above and/or
                        respond to any governmental or legal proceedings.
                    </p>
                    <p className="mb-4">
                        <b>Opt-Out Rescission</b>. If you opt out per the
                        process set forth above, but then either (a) provide
                        your Personal Information to us again using the Site,
                        (b) provide your Personal Information to us again using
                        a related Site and/or quote form owned by a Detroit
                        Trading affiliate or parent or (c) under other
                        circumstances permit us to use your Personal
                        Information, then we will regard your opt-out as
                        rescinded.
                    </p>
                    <p className="mb-4">
                        Detroit Trading does not share Personal Information it
                        collects to operate its services with third parties who
                        are not Clients for the marketing purposes of those
                        third parties who are not Clients.
                    </p>
                    <br></br>
                    <h3 className="mb-2 text-2xl font-medium lg:text-[1.75rem]">
                        Changes to this Privacy Statement
                    </h3>
                    <br></br>
                    <p className="mb-4">
                        Detroit Trading may update this policy from time to
                        time. If we make changes, we will revise the date at the
                        top of this Privacy Statement. In some cases, we will
                        also email users who have opted to receive
                        communications from us, notifying them of the changes.
                    </p>
                    <br></br>
                    <h3 className="mb-2 text-2xl font-medium lg:text-[1.75rem]">
                        Other important information
                    </h3>
                    <br></br>
                    <p className="mb-4">
                        The services that Detroit Trading offers on this Site
                        are intended to be used by residents of the fifty (50)
                        United States who are of the legal age to purchase and
                        operate a motor vehicle in their state of residence. The
                        information that these persons provide is stored on
                        secure servers located in the United States.
                    </p>
                    <br></br>
                    <h3 className="mb-2 text-2xl font-medium lg:text-[1.75rem]">
                        Disputes
                    </h3>
                    <br></br>
                    <p className="mb-4">
                        This Privacy Statement, the use of your Personal
                        Information and any dispute you may have related to such
                        is governed by the laws of the State of Michigan, USA.
                        By accessing this Form and by using the services offered
                        on the Site, you consent to the exclusive jurisdiction
                        and venue of the courts in Oakland County, Michigan,
                        USA.
                    </p>
                    <br></br>
                    <h3 className="mb-2 text-2xl font-medium lg:text-[1.75rem]">
                        Questions and Suggestions
                    </h3>
                    <br></br>
                    <p className="mb-4">
                        If you have questions or suggestions about this Privacy
                        Statement or any experience you have had with the Site,
                        please contact our parent company at:
                    </p>
                    <p className="mb-4">Detroit Trading Services, LLC</p>
                    <p className="mb-4">Attn: Privacy Statement Inquiries</p>
                    <p className="mb-4">13335 15 Mile Road</p>
                    <p className="mb-4">Sterling Heights, MI 48312-4271</p>
                    <p className="mb-4">248.352.1313</p>
                    <p className="mb-4">
                        NOTICE:{' '}
                        <a
                            className="text-blue-dark"
                            href="https://www.ftc.gov/bcp/consumer.shtm"
                        >
                            click here
                        </a>{' '}
                        for important information about safe surfing from the
                        Federal Trade Commission.
                    </p>
                    <br></br>
                    <h4 className="mb-2 text-xl font-medium lg:text-2xl">
                        Privacy Statement - Abbreviated Terms
                    </h4>
                    <h4 className="mb-2 text-xl font-medium lg:text-2xl">
                        Effective Date:{' '}
                        <span className="font-normal">December 20, 2015</span>
                    </h4>
                    <h4 className="mb-2 text-xl font-medium lg:text-2xl">
                        Revised:{' '}
                        <span className="font-normal">January 1, 2022</span>
                    </h4>
                    <br></br>
                    <div>
                        <ol className="list-decimal pl-8">
                            <li className="mb-0">
                                <b>
                                    TCPA – Auto Finance (place immediately below
                                    CTA where consumer inputs phone number and
                                    personal information)
                                </b>
                            </li>
                            <p className="mb-4 text-xs">
                                <i>
                                    *Car Deal Finder and/or participating
                                    lenders, dealers or affiliates may contact
                                    me on any number I provide, whether a mobile
                                    phone number or a land line number, using
                                    automated telephone dialing systems, text
                                    and/or pre-recorded messages for
                                    telemarketing purposes. By providing a
                                    telephone number and completing a quote
                                    form, I am expressly consenting to these
                                    methods of contact. I understand that calls
                                    and messages to any mobile phone number may
                                    incur access fees from my cellular provider.
                                    I understand that consent is not required as
                                    a condition of these services.
                                </i>
                            </p>
                            <li className="mb-0">
                                <b>
                                    FCRA Consents – Auto Finance (place
                                    immediately below TCPA disclaimer)
                                </b>
                            </li>
                            <p className="mb-4 text-xs">
                                <i>
                                    [ ] I have read the{' '}
                                    <a href="/privacy-policy">
                                        Privacy Statement
                                    </a>{' '}
                                    and <a href="/terms-of-use">Terms of Use</a>
                                    , agree to receive electronic documents and
                                    certain disclosures.
                                </i>
                            </p>
                            <p className="mb-4 text-xs">
                                <i>
                                    [ ] I understand that by clicking the “GET
                                    PRE-QUALIFIED” button, I am providing
                                    “written instruction” under the Fair Credit
                                    Reporting Act (“FCRA”) authorizing Westlake
                                    Financial, its partners and/or its
                                    affiliates to obtain a personal credit
                                    profile and/or other information from
                                    Experian or a similar credit bureau for the
                                    sole purposes of credit pre-qualification.
                                </i>
                            </p>
                            <li className="mb-0">
                                <b>
                                    Vehicle Pricing (after Amount Financed CTA,
                                    in box)
                                </b>
                            </li>
                            <p className="mb-4 text-xs">
                                <i>
                                    Taxes and fees are estimated and may vary.
                                    This pre-qualification pricing and offer is
                                    based on the assumption of the accuracy and
                                    verification of the information you provided
                                    and does not constitute a determination of
                                    credit worthiness or a firm offer of credit.
                                </i>
                            </p>
                            <li className="mb-0">
                                <b>CCPA – Auto Finance (in Footer)</b>
                            </li>
                            <p className="mb-4 text-xs">
                                <i>
                                    Detroit Trading and [mycarmatch /
                                    Rydeshopper / MotorTrend] offers this
                                    service to help you get pre-qualified for
                                    financing offers online. We will share the
                                    information you provide as a service
                                    provider under the California Consumer
                                    Privacy Act with participating dealerships,
                                    brands and lending institutions with whom we
                                    have a direct agreement. They will contact
                                    you to discuss financing options available
                                    to you. By using these services, you consent
                                    to us sharing the information you provide
                                    with these entities, and you accept the
                                    terms outlined in our{' '}
                                    <a href="/terms-of-use">Terms of Use</a> and{' '}
                                    <a href="/privacy-policy">
                                        Privacy Statement
                                    </a>
                                    .
                                </i>
                            </p>
                            <li className="mb-0">
                                <b>Image Disclaimers (in Footer)</b>
                            </li>
                            <p className="mb-4 text-xs">
                                <i>
                                    © NOTICE: The automotive images or video
                                    contained here\in are owned by EVOX
                                    Productions LLC and are protected under
                                    United States and international copyright
                                    law.
                                </i>
                            </p>
                            <li className="mb-0">
                                <b>Data Disclaimers (in Footer)</b>
                            </li>
                            <p className="mb-4 text-xs">
                                <i>
                                    © Certain automotive content displayed
                                    within this website Copyright{' '}
                                    <a
                                        href="https://www.dataonesoftware.com/"
                                        target="_blank"
                                    >
                                        DataOne Software
                                    </a>{' '}
                                    and are protected under the United States
                                    and international copyright law. Any
                                    unauthorized use, reproduction,
                                    distribution, recording or modification of
                                    this content is strictly prohibited.
                                </i>
                            </p>
                            <li className="mb-0">
                                <b>Copyright Disclaimers (in Footer)</b>
                            </li>
                            <p className="mb-4 text-xs">
                                <i>
                                    © 2022 Detroit Trading. All Rights Reserved.
                                </i>
                            </p>
                            <li className="mb-0">
                                <b>Dynamic Payment Disclaimer</b>
                            </li>
                            <p className="mb-4 text-xs">
                                <i>
                                    *The Dealer Price and Estimated Monthly
                                    Payment are not an advertisement or offer
                                    for specific terms of credit. Payment
                                    amounts presented are estimates and are for
                                    illustrative purposes only. The Estimated
                                    Monthly Payment is based on a 48-month term,
                                    a down payment of $800, Annual Percentage
                                    Rate (APR) of 12.49%, taxes in the amount of
                                    $914, title fees in the amount of $408, doc
                                    fees in the amount of $55, smog fees in the
                                    amount of $50, smog certificate fees in the
                                    amount of $8 and no trade-in. If you change
                                    the Down Payment or Term, or if you trade in
                                    your current vehicle, the Estimated Monthly
                                    Payment will change. The payment amount does
                                    not include additional title, license or
                                    registration fees. Payment amounts may be
                                    different due to various factors such as
                                    fees, specials, and rebates, term, down
                                    payment, APR, trade-in, and applicable tax
                                    rate. Actual APR is based on the
                                    creditworthiness of the customer. Not all
                                    customers will qualify for the lowest rate.
                                    You, your dealer and/or lender will
                                    determine your actual APR. Please contact
                                    your selected dealer for program details and
                                    actual terms. The Dealer Price excludes
                                    transportation and handling charges,
                                    destination charges, taxes, title,
                                    registration, preparation and documentary
                                    fees, tags, labor and installation charges,
                                    insurance, optional equipment or packages
                                    and accessories
                                </i>
                            </p>
                        </ol>
                    </div>
                </div>
            </div>
        </SimpleLayout>
    );
};

export default PrivacyPolicy;
