declare global {
    interface Window {
        dataLayer?: object[];
    }
}

export const windowOrigin = (test?: boolean) => {
    // console.log("Window Origin:", window.origin);
    if (window.origin === 'https://carpayment.info' || test) {
        return true;
    } else {
        return false;
    }
};

export const gaLoanAmount = (e: any) => {
    if (windowOrigin()) {
        const value: string = e.value;
        window.dataLayer = window.dataLayer || [];
        return window.dataLayer.push({
            event: 'LoanAmount',
            loan_amount: value,
        });
    }
};

export const gaLoanAmountSlider = (e: any) => {
    if (windowOrigin()) {
        const value: string = e.toString();
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            event: 'LoanAmount',
            loan_amount: value,
        });
    }
};

export const gaCreditScore = (e: any) => {
    if (windowOrigin()) {
        const value: string = e.value;
        window.dataLayer = window.dataLayer || [];
        return window.dataLayer.push({
            event: 'CreditScore',
            credit_score: value == '0' ? 'Custom' : value,
        });
    }
};

export const gaInterestRate = (e: any) => {
    if (windowOrigin()) {
        const value: string = e;
        window.dataLayer = window.dataLayer || [];
        return window.dataLayer.push({
            event: 'InterestRate',
            interest_rate: value,
        });
    }
};

export const gaZipCode = (e: any) => {
    if (windowOrigin()) {
        const value: string = e;
        window.dataLayer = window.dataLayer || [];
        return window.dataLayer.push({
            event: 'ZipCode',
            zip_code: value,
        });
    }
};

export const srpViewEvent = () => {
    if (windowOrigin()) {
        window.dataLayer = window.dataLayer || [];
        return window.dataLayer.push({
            event: 'SrpView',
        });
    }
};

export const srpFilterEvent = () => {
    if (windowOrigin()) {
        window.dataLayer = window.dataLayer || [];
        return window.dataLayer.push({
            event: 'SrpFilter',
        });
    }
};

export const srpVehicleSelectEvent = () => {
    if (windowOrigin()) {
        window.dataLayer = window.dataLayer || [];
        return window.dataLayer.push({
            event: 'SrpVehicleSelect',
        });
    }
};

export const gaFormEnteranceClick = () => {
    if (windowOrigin()) {
        window.dataLayer = window.dataLayer || [];
        return window.dataLayer.push({
            event: 'InvFormView',
        });
    }
};

export const invTypViewEvent = () => {
    if (windowOrigin()) {
        window.dataLayer = window.dataLayer || [];
        return window.dataLayer.push({
            event: 'InvTypView',
        });
    }
};
