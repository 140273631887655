import { useContext } from 'react';
import { UsedVehicleContext } from '../context/used-vehicle-context';
import Button from './Button';
import { useNavigate } from 'react-router-dom';
import { bodyOptions } from '../pages/SearchUsedVehicles/SearchUsedVehiclesScripts';
import { inventoryCall } from '../helpers/getInventory';
import { GrMapLocation } from 'react-icons/gr';
import { Context as HarvesterContext } from '../helpers/harvester/react/Provider';
import {
    itTypHomeClick,
    itTypShopMoreUsedClick,
} from '../helpers/harvester-events';

type ThankYouProps = {
    closeCart: () => void;
    closeForm: () => void;
    success: () => void;
};

const ThankYou: React.FC<ThankYouProps> = (props: ThankYouProps) => {
    const { harvester } = useContext(HarvesterContext);
    const { usedVehicleState, updateUsedVehicleState } =
        useContext(UsedVehicleContext);
    const { selectedInventory, selectedKeys, searchQueryParams } =
        usedVehicleState;
    const navigate = useNavigate();

    const handleGoHome = () => {
        const exclusion: Array<string> = [];
        selectedKeys.map((key: string) => {
            exclusion.push(key);
        });
        updateUsedVehicleState({
            ...usedVehicleState,
            excludeKeys: exclusion,
            selectedKeys: [],
            selectedInventory: [],
        });
        itTypHomeClick(harvester);
        navigate('/');
    };

    const handleShopMore = async () => {
        const exclusion: Array<string> = [];
        selectedKeys.map((key: string) => {
            exclusion.push(key);
        });
        const bodyTypes = bodyOptions.map((bodyOption) => {
            return bodyOption.display;
        });
        const bodyTypeValues = bodyOptions.map((bodyOption) => {
            return bodyOption.bodyTypes;
        });
        const inventory = await inventoryCall(
            searchQueryParams,
            undefined,
            exclusion
        );
        if (inventory && inventory.facets && inventory.hits) {
            const vehicleCount = inventory.hits.found;
            updateUsedVehicleState({
                ...usedVehicleState,
                bodyCounts: vehicleCount,
                vehicleInventory: inventory.hits.hit,
                excludeKeys: exclusion,
                selectedBodyTypes: bodyTypes,
                selectedBodyTypeValues: bodyTypeValues.flat(),
                selectedKeys: [],
                selectedInventory: [],
            });
            itTypShopMoreUsedClick(harvester);
            props.success();
            props.closeCart();
            props.closeForm();
        }
    };

    return (
        <div>
            <p className="text-base text-stone-500">
                The dealers below have received your request and will contact
                you shortly.
            </p>
            {selectedInventory.map((vehicle: any, index: number) => {
                return (
                    <div
                        key={index}
                        className="my-4 cursor-pointer rounded border-2 border-stone-300 p-2 font-bold text-stone-500"
                        onClick={() => {
                            window.open(
                                `https://maps.google.com?q=${vehicle.vehicleLocation}`
                            );
                        }}
                    >
                        <p>{vehicle.vehicleLocation}</p>
                        <p className="flex flex-row text-sm">
                            {vehicle.vehicleYear}&nbsp;
                            {vehicle.vehicleMake}&nbsp;
                            {vehicle.vehicleModel}&nbsp;
                            <GrMapLocation />
                        </p>
                    </div>
                );
            })}
            <div className="flex flex-col items-center gap-y-4">
                <Button
                    className="h-12 w-full"
                    onClick={() => handleShopMore()}
                >
                    Shop More Used
                </Button>
                <Button className="h-12 w-full" onClick={() => handleGoHome()}>
                    Go Home
                </Button>
            </div>
        </div>
    );
};

export default ThankYou;
