import React from 'react';
import DataOne from '../images/dataone.png';
import SimpleLayout from '../components/SimpleLayout';
import SimpleHeader from '../components/SimpleHeader';
import ScrollToTop from '../components/ScrollToTop';

const TermsOfUse: React.FC = ({ location }: any) => {
    return (
        <SimpleLayout pageTitle="Terms of Use">
            <SimpleHeader />
            <ScrollToTop />
            <div className="mt-4 font-manrope">
                <div className="terms-of-use-page">
                    <div className="mx-auto w-full px-3 md:max-w-[720px] xl:max-w-7xl">
                        <h2 className="mb-2 text-2xl font-medium lg:text-[2rem]">
                            Terms of Use
                        </h2>
                        <h4 className="mb-2 text-xl font-medium lg:text-2xl">
                            Auto Finance
                        </h4>
                        <h4 className="mb-2 text-xl font-medium lg:text-2xl">
                            Effective Date:{' '}
                            <span className="font-normal">
                                February 26, 2016
                            </span>
                        </h4>
                        <h4 className="mb-2 text-xl font-medium lg:text-2xl">
                            Revised Date:{' '}
                            <span className="font-normal">January 1, 2022</span>
                        </h4>
                        <br />
                        <h3 className="mb-2 text-2xl font-medium lg:text-[1.75rem]">
                            Welcome
                        </h3>
                        <br />
                        <p className="mb-4">
                            Thank you for your interest in these shopping tools
                            and car shopping content channels (the “Site”)
                            developed by Detroit Trading Company (“Detroit
                            Trading”), a leading online automotive information
                            and communications platform. Detroit Trading is
                            amongst the world’s foremost aggregators of
                            “in-market” shopper intelligence, providing data to
                            original equipment manufacturers and dealers that
                            are responsible for more than tens of thousands of
                            monthly individual vehicle sales. We encourage you
                            to read the following terms governing your use of
                            and purchase of products from the Site. By using the
                            Site or by providing personal information to us, you
                            agree to be bound by these Terms of Service set
                            forth below (the “Terms”).
                        </p>

                        <p className="mb-4">
                            The Site provides users with various services,
                            including – but not limited to – an online platform
                            that enables you to (1) comparison shop new and/or
                            used vehicle pricing based on style, available
                            dealer inventory, segment, brand and/or payment
                            amount; (2) fill out online quote forms to ask
                            questions, learn about available manufacturer
                            incentives, estimate the trade-in value of a vehicle
                            and be placed in contact with an original equipment
                            manufacturer, local vehicle dealer and/or seller;
                            and/or (3) learn more about pricing on new and/or
                            used vehicle inventory through a toll free telephone
                            number (the “Services”).
                        </p>
                        <br />
                        <h3 className="mb-2 text-2xl font-medium lg:text-[1.75rem]">
                            General
                        </h3>
                        <br />
                        <p className="mb-4">
                            By using this Site and any related services offered
                            on this Site, you agree and understand:
                        </p>
                        <ol className="mb-4 list-decimal pl-8">
                            <li>
                                That neither Detroit Trading, its parents nor
                                its subsidiaries/affiliates are a party to any
                                agreement(s) entered into between sellers and/or
                                dealers and buyers;
                            </li>
                            <li>
                                That neither Detroit Trading, its parents nor
                                its subsidiaries/affiliates are a dealership,
                                broker, agent, administrator, financer or
                                insurer of any new vehicles;
                            </li>
                            <li>
                                That the content that appears on Detroit Trading
                                is for informational purposes only and that it
                                may, from time to time, contain errors – despite
                                our best efforts. To this extent, we make no
                                guarantees regarding the accuracy, timeliness,
                                completeness or reliability of any of the
                                materials or information contained on the Site;
                            </li>
                            <li>
                                Detroit Trading does not have any control over
                                the conduct of inventory providers, sellers,
                                dealers, buyers or other users of the Site, the
                                services offered on the Site or any
                                representations made about any vehicle by a
                                dealer or seller whose vehicle is posted on the
                                Site, despite our hope all parties who use the
                                Site act honorably and treat each other fairly.
                            </li>
                        </ol>
                        <p className="mb-4">
                            When used in these Terms, Detroit Trading shall
                            include any parent companies, affiliates, directors,
                            officers, and employees.
                        </p>
                        <p className="mb-4">
                            These Terms are a legally binding agreement between
                            you and Detroit Trading. Please read them carefully.
                        </p>
                        <br />
                        <h3 className="mb-2 text-2xl font-medium lg:text-[1.75rem]">
                            Modifications to these Terms
                        </h3>
                        <br />
                        <p className="mb-4">
                            From time to time, we may change the Terms that
                            govern your use of our Site and any related
                            services. Your continued use of our Site following
                            any such changes to these Terms constitutes your
                            agreement to follow and be bound by the terms as
                            changed. When such a modification is made, we will
                            post a revised version of the Terms on this Site.
                            Changes will be effective when they are posted.
                            Detroit Trading is not required to provide you with
                            notification that any such change has been made.
                        </p>
                        <br />
                        <h3 className="mb-2 text-2xl font-medium lg:text-[1.75rem]">
                            Site Contents
                        </h3>
                        <br />
                        <p className="mb-4">
                            Except as otherwise indicated, information on this
                            Site is protected by copyright in the United States
                            of America and in other countries. Detroit Trading
                            and its parents owns all worldwide right, title and
                            interest in and to the Site. Site contents are
                            either used under licensing agreements and/or are
                            used in accordance with industry standards and/or
                            the applicable terms of use as disclosed by content
                            providers. Unless otherwise specified (see the
                            Limited Disclaimer of Copyright below), no one has
                            permission to copy or republish, in any form, any
                            information and/or combination of information found
                            on this Site.
                        </p>
                        <p className="mb-4">
                            The posting of information or other materials to
                            this Site does not constitute a waiver of any
                            proprietary right in such information and materials
                            (such as, but not limited to, copyright, service
                            marks, trademarks, trade dress, trade names, or
                            other intellectual property) and does not transfer
                            any rights to a user of the Site or any other third
                            party, except as expressly provided herein.
                        </p>
                        <br />
                        <h3 className="mb-2 text-2xl font-medium lg:text-[1.75rem]">
                            Vehicle Listings
                        </h3>
                        <br />
                        <p className="mb-4">
                            The prices listed by sellers and dealers on Detroit
                            Trading often exclude sales tax; finance charges;
                            title; and license, regulatory, dealer
                            documentation, emission testing and compliance fees.
                            Any or all of these fees may be added by a dealer or
                            seller to the listed price prior to sale of a listed
                            vehicle. Please contact the dealer or seller for
                            full details and final pricing.
                        </p>
                        <p className="mb-4">
                            Detroit Trading is not responsible for the
                            condition, performance or quality of any of the
                            vehicles, goods or services listed on the Site.
                        </p>
                        <p className="mb-4">
                            All transactions are conducted by and fulfilled
                            through sellers and dealers that are not owned by or
                            affiliated with Detroit Trading. Detroit Trading
                            does not guarantee vehicle prices or availability.
                        </p>
                        <br />
                        <h3 className="mb-2 text-2xl font-medium lg:text-[1.75rem]">
                            Service Marks and Trade Marks
                        </h3>
                        <br />
                        <p className="mb-4">
                            Detroit Trading, Detroit Trading Exchange, Detroit
                            Trading Services, DTC, Rydeshopper, IncentiveFinder,
                            mycarmatch, Deal Finder and Car Deal Finder are
                            trade dress, service marks and/or trademarks of
                            Detroit Trading Company. Manufacturer and/or dealer
                            trademarks are used under license or in accordance
                            to industry standards.
                        </p>
                        <br />
                        <h3 className="mb-2 text-2xl font-medium lg:text-[1.75rem]">
                            Copyright, Limited Disclaimer of Copyright, Digital
                            Millennium Copyright Act
                        </h3>
                        <br />
                        <p className="mb-4">
                            Except as otherwise indicated, information on this
                            Site is protected by copyright in the United States
                            of America and in other countries. Unless otherwise
                            specified, no one has permission to copy or
                            republish, in any form, any information found on
                            this Site.
                        </p>
                        <p className="mb-4">
                            Any authorized person may view the information and
                            incidentally create an electronic copy of the
                            information available from this Site for
                            informational, non-commercial purposes only. No part
                            of the information at this Site can be otherwise
                            redistributed, copied, or reproduced in any form
                            without prior written consent of Detroit Trading.
                        </p>
                        <p className="mb-4">
                            Certain automotive content displayed within this
                            website are the copyright of Dataone Software and
                            are protected under the United States and
                            international copyright law. Any unauthorized use,
                            reproduction, distribution, recording or
                            modification of this content is strictly prohibited.
                            Dataone Software is an automotive data source
                            providing data to solution and service providers in
                            North America across many sectors including
                            automotive marketing, transportation, and risk
                            management.
                        </p>
                        <img src={DataOne} alt="DataOne Logo" />
                        <br />
                        <p className="mb-4">
                            Other certain automotive images or video contained
                            on Detroit Trading are owned by EVOX Productions,
                            LLC and are also protected under United States and
                            international copyright law.
                        </p>
                        <p className="mb-4">
                            In accordance with the Digital Millennium Copyright
                            Act of 1998 (see www.copyright.gov for full text),
                            Detroit Trading will respond to claims of copyright
                            infringement committed using the Site when such
                            claims are reported to Detroit Trading. If you are a
                            copyright owner, or authorized to act on behalf of
                            an owner of the copyright or of any exclusive right
                            under the copyright and believe your or such owner’s
                            copyright has been infringed by an improper posting
                            or distribution via this Site, please report the
                            infringement by sending an email to the copyright
                            agent at mattc@detroittrading.com.
                        </p>
                        <br />
                        <h3 className="mb-2 text-2xl font-medium lg:text-[1.75rem]">
                            User Submissions
                        </h3>
                        <br />
                        <p className="mb-4">
                            Any and all comments, feedback, postcards,
                            suggestions, ideas, and other submissions disclosed,
                            submitted or offered in connection with your use of
                            this Site (collectively, "Submissions") shall be and
                            remain Detroit Trading property. Detroit Trading is
                            not under any obligation to (1) maintain any
                            Submissions in confidence; (2) pay to user any
                            compensation for any Submissions; or (3) respond to
                            any user Submissions.
                        </p>
                        <p className="mb-4">
                            You agree any Submission you make to the Site does
                            not violate any right of any third party, including
                            copyright, trademark, privacy, patent or other
                            personal or proprietary right(s). In addition, if
                            any Submission contains your or any other person’s
                            name, likeness, voice or biographical information
                            (“Personally Identifiable Information”), you grant
                            and will cause the other person to grant to Detroit
                            Trading a transferable, non-exclusive, worldwide,
                            royalty-free license to use such Personal
                            Identifiable Information for any advertising or
                            marketing of Detroit Trading’s products in any
                            medium. You agree to indemnity and hold harmless
                            Detroit Trading against any losses, liabilities,
                            claims and expenses (including attorneys’ fees)
                            arising from your use of this Site, your Submission
                            and any Personally Identifiable Information
                            contained therein.
                        </p>
                        <p className="mb-4">
                            You further agree that the Submission you submit to
                            the Site does not contain libelous or otherwise
                            unlawful, abusive or obscene material. You are and
                            remain solely responsible for the content of any of
                            your Submissions. Electronic transmissions to and
                            from this Site are not confidential and may be read
                            or intercepted by others.
                        </p>
                        <br />
                        <h3 className="mb-2 text-2xl font-medium lg:text-[1.75rem]">
                            CAN-SPAM, Telephone Consumer Protection Act and
                            Similar Law
                        </h3>
                        <br />
                        <p className="mb-4">
                            Your use of this Site establishes a business or
                            other applicable relationship with us for purposes
                            of CAN-SPAM, the Telephone Consumer Protection Act
                            and all other applicable law that addresses
                            unsolicited commercial communications. By visiting
                            this Site and using its features, you agree that we,
                            our agents or third parties to whom we provide your
                            information may call, e-mail, SMS text message or
                            otherwise communicate with you regarding the sale or
                            lease of motor vehicles.
                        </p>
                        <p className="mb-4">
                            By visiting the Site and using the services, you
                            give to us and/or our third party partners the right
                            to contact you and to attempt to sell you one or
                            more vehicles. This includes, without limitation,
                            packaging your information and providing it, for
                            compensation or otherwise, to persons who purchase
                            and/or repurchase your packaged information as a
                            sale’s lead. You grant your permission for us to do
                            this (and to do all other things reasonably
                            necessary to accomplish this purpose) and for one or
                            more recipients of the information to try to sell
                            you a vehicle. The uses of your information are not
                            limited to sales of motor vehicles.
                        </p>
                        <p className="mb-4">
                            Please see our Privacy Policy for more details.
                        </p>
                        <br />
                        <h3 className="mb-2 text-2xl font-medium lg:text-[1.75rem]">
                            Disclaimer of Warranties
                        </h3>
                        <br />
                        <p className="mb-4">
                            THIS INFORMATION ON THIS SITE IS PROVIDED "AS-IS"
                            WITHOUT WARRANTY OF ANY KIND, EITHER EXPRESSED OR
                            IMPLIED, INCLUDING, BUT NOT LIMITED TO, THE IMPLIED
                            WARRANTIES OF MERCHANTABILITY, TITLE, FITNESS FOR A
                            PARTICULAR PURPOSE, NON-INFRINGEMENT, OR ANY ARISING
                            OUT OF THE COURSE OF DEALING OR TRADE.
                        </p>
                        <p className="mb-4">
                            DETROIT TRADING MAKES NO REPRESENTATION AS TO THE
                            ACCURACY, TIMELINESS, OR COMPLETENESS OF THE SITE OR
                            MATERIALS CONTAINED ON THE SITE OR THE SERVICES IT
                            PROVIDES, INCLUDING ANY RELATED INFORMATION,
                            TECHNICAL ASSISTANCE, REPRESENTATIONS OR GRAPHICS.
                            DETROIT TRADING ASSUMES NO LIABILITY OR
                            RESPONSIBILITY FOR ANY ERRORS OUR OMISSIONS IN THE
                            CONTENT OF THE SITE OR SERVICES IT PROVIDES. DETROIT
                            TRADING SPECIFICALLY DISCLAIMS ANY DUTY TO UPDATE
                            THE INFORMATION ON THE SITE.
                        </p>
                        <p className="mb-4">
                            REFERENCE TO DETROIT TRADING AND ITS SERVICES AND
                            PRODUCTS ARE PROVIDED "AS IS" WITHOUT WARRANTY OF
                            ANY KIND, EITHER EXPRESSED OR IMPLIED.
                        </p>
                        <p className="mb-4">
                            DESCRIPTIONS OF, OR REFERENCES TO, PRODUCTS,
                            SERVICES OR PUBLICATIONS WITHIN THE DETROIT TRADING
                            SITE DO NOT IMPLY ENDORSEMENT OF THAT PRODUCT,
                            SERVICE, OR PUBLICATION. DETROIT TRADING MAKES NO
                            WARRANTY OF ANY KIND WITH RESPECT TO THE SUBJECT
                            MATTER OR PRODUCTS OFFERED ON THE SITE OR ANY
                            RELATED SERVICE INCLUDING THE QUALITY OF ANY VEHICLE
                            LISTING AND/OR SERVICES OFFERED. DETROIT TRADING
                            SPECIFICALLY DISCLAIMS ALL WARRANTIES, EXPRESSED,
                            IMPLIED, OR OTHERWISE, INCLUDING, WITHOUT
                            LIMITATION, ALL WARRANTIES OF MERCHANTABILITY AND
                            FITNESS FOR A PARTICULAR PURPOSE.
                        </p>
                        <p className="mb-4">
                            Some states do not allow the disclaimer of
                            warranties or the exclusion of liability for
                            consequential damages, so the above limitations may
                            not apply to you in all cases.
                        </p>
                        <br />
                        <h3 className="mb-2 text-2xl font-medium lg:text-[1.75rem]">
                            Typographic Errors
                        </h3>
                        <br />
                        <p className="mb-4">
                            This Site could include technical inaccuracies or
                            typographical errors. Detroit Trading assumes no
                            liability to any injury, whether financial or other,
                            that arises from a technical inaccuracy or
                            typographical error.
                        </p>
                        <br />
                        <h3 className="mb-2 text-2xl font-medium lg:text-[1.75rem]">
                            Limitations of Liability
                        </h3>
                        <br />
                        <p className="mb-4">
                            By accessing the Site or by using any service
                            offered by Detroit Trading, you agree to release and
                            hold harmless the Detroit Trading from and against
                            any and all claims, damages, liabilities, judgments,
                            costs and expenses arising out of participation in
                            any activity, whether online or offline, offered by
                            Detroit Trading. Neither Detroit Trading nor any
                            other party involved in creating, producing or
                            managing the Site and or any Detroit Trading
                            services will be liable for any incidental, special,
                            exemplary or consequential damages, including lost
                            profits, loss of data or loss of goodwill, service
                            interruption, computer damage or system failure or
                            the cost of substitute products or services, or for
                            any damages for personal or bodily or personal
                            injury or distress arising (1) in connection with
                            the use or inability to use this site; (2) from any
                            communications, meetings, or interactions with
                            Detroit Trading employees or with other users of the
                            site, including buyers, sellers, dealers and/or
                            service providers – whether based on warranty,
                            contract, tort (including negligence), product
                            liability or any other legal theory.
                        </p>
                        <p className="mb-4">
                            IN NO EVENT SHALL DETROIT TRADING’S LIABILITY TO
                            USERS FOR ALL DAMAGES, LOSSES, AND CAUSES OF ACTION
                            (WHETHER IN CONTRACT OR TORT, INCLUDING, BUT NOT
                            LIMITED TO, NEGLIGENCE OR OTHERWISE) EXCEED THE
                            AMOUNT, IF ANY, PAID BY USERS TO DETROIT TRADING,
                            FOR ACCESSING THE SITE OR USING ITS SERVICES. YOU
                            SHOULD CONSULT YOUR LEGAL ADVISORS REGARDING THE
                            APPLICABILITY OF STATE STATUTES REGARDING
                            LIMITATIONS ON LIABILITY.
                        </p>
                        <br />
                        <h3 className="mb-2 text-2xl font-medium lg:text-[1.75rem]">
                            Indemnification by User
                        </h3>
                        <br />
                        <p className="mb-4">
                            USER AGREES TO INDEMNIFY, DEFEND AND HOLD DETROIT
                            TRADING HARMLESS FROM ANY LIABILITY, LOSS, CLAIM AND
                            EXPENSE, INCLUDING ATTORNEY’S FEES RELATED TO (1) A
                            USER’S VIOLATION OF THESE TERMS AND CONDITIONS OR
                            (2) USER’S USE OF THESE SERVICES, INCLUDING BUT NOT
                            LIMITED TO THE TECHNICAL SUPPORT AND THE INFORMATION
                            PROVIDED AT THIS SITE OR THROUGH ANY RELATED THIRD
                            PARTY.
                        </p>
                        <br />
                        <h3 className="mb-2 text-2xl font-medium lg:text-[1.75rem]">
                            Viruses
                        </h3>
                        <br />
                        <p className="mb-4">
                            All responsibility or liability for any damages
                            caused by viruses contained within the electronic
                            files or at this Site is disclaimed.
                        </p>
                        <br />
                        <h3 className="mb-2 text-2xl font-medium lg:text-[1.75rem]">
                            Links to Other Websites
                        </h3>
                        <br />
                        <p className="mb-4">
                            This Site may contain links to other web sites not
                            owned or managed by Detroit Trading or its
                            affiliates. Detroit Trading is not responsible for
                            the content, accuracy, or opinions expressed in such
                            web sites, and such web sites are not investigated,
                            monitored, or checked for accuracy or completeness
                            by Detroit Trading. Inclusion of any such linked web
                            site does not imply approval or endorsement of the
                            linked web site by Detroit Trading.
                        </p>
                        <br />
                        <h3 className="mb-2 text-2xl font-medium lg:text-[1.75rem]">
                            Governing Law
                        </h3>
                        <br />
                        <p className="mb-4">
                            These Terms will be governed by and construed in
                            accordance with the laws of the United States of
                            America and the State of Michigan without regard to
                            conflict of law principles. Users agree that all
                            legal proceedings related to the matters shall be
                            adjudicated solely in the courts of Oakland County,
                            Michigan, United States of America. Users consent to
                            extra-territorial service of process and submit to
                            the jurisdiction of these courts.
                        </p>
                        <p className="mb-4">
                            To the extent any portion of these Terms are
                            unenforceable, such portion will be modified by the
                            court solely to the extent necessary to cause such
                            portion to be enforceable, and these Terms of Use as
                            so modified will remain in full force and effect.
                        </p>
                        <p className="mb-4">
                            Any waiver of any provisions contained in these
                            Terms by Detroit Trading shall not be deemed to be a
                            waiver of any other right, term, or provision of
                            these Terms. Any waiver of these Terms must be in
                            writing and signed by our authorized representative.
                            A printed version of these Terms and of any notice
                            given in electronic form shall be admissible in
                            judicial or administrative proceedings based upon or
                            relating to these Terms to the same extent and
                            subject to the same conditions as other business
                            documents and records originally generated and
                            maintained in printed form.
                        </p>
                    </div>
                </div>
            </div>
        </SimpleLayout>
    );
};

export default TermsOfUse;
