import isMobileCheck from '../helpers/isMobileCheck';

type FormInputProps = {
    children: any;
    title: string;
    errorMessage?: string;
};

const FormInput: React.FC<FormInputProps> = (props: FormInputProps) => {
    return (
        <div className="flex flex-col max-md:w-[inherit] md:max-w-[227px]">
            <p
                className={
                    'mb-1 text-xs font-normal' +
                    (props.errorMessage
                        ? ' ' + 'text-red-500'
                        : ' ' + 'text-stone-700')
                }
            >
                {props.errorMessage && '*'}
                {props.title}
                {props.errorMessage && ' ' + props.errorMessage}
            </p>
            {props.children}
        </div>
    );
};

export default FormInput;
