import { hash, serialize } from './util.js';
import { Harvester } from './Harvester.js';

export class BatchHarvester extends Harvester {
    constructor({ modules = {}, debounce = 250, globals = {} } = {}) {
        super({ globals });
        this.debounceTime = debounce;
        this.moduleState = new Map();

        Object.entries(modules).forEach(([name, opts]) =>
            this.addModule(name, opts)
        );
    }

    addModule(name, opts = {}) {
        super.addModule(name, opts);
        this.moduleState.set(name, {
            queue: [],
            lastSendTime: 0,
            timerId: null,
        });

        return this;
    }

    removeModule(name) {
        super.removeModule(name);
        this.moduleState.delete(name);

        return this;
    }

    async process(event, properties, targetModule = null) {
        this.modules.forEach((module) => {
            if (targetModule && targetModule !== module.name) return;

            const globals = this.getGlobals(module.name, {
                hash: hash(serialize([event, properties])),
            });

            if (module.filter(event, properties, globals)) {
                this.enqueue(module, event, properties, globals);
            }
        });

        return this;
    }

    async dispatch(event, properties = {}) {
        return this.process(event, properties);
    }

    async to(name, event, properties = {}) {
        return this.process(event, properties, name);
    }

    enqueue(module, event, properties, globals) {
        const name = module.name;
        const state = this.moduleState.get(name);
        const currentTime = Date.now();
        const timeSinceLastSend = currentTime - state.lastSendTime;

        state.queue.push([event, properties, globals]);

        if (state.timerId || timeSinceLastSend < this.debounceTime) {
            clearTimeout(state.timerId);
            state.timerId = setTimeout(
                () => this.flush(module),
                this.debounceTime
            );
        } else {
            this.flush(module);
            state.lastSendTime = currentTime;
        }
    }

    flush(module) {
        const name = module.name;
        const state = this.moduleState.get(name);

        if (state.queue.length > 0) {
            if (module.batch && state.queue.length > 1) {
                module.batch(
                    state.queue.map((v) => v.slice(0, 2)),
                    { ...this.globals, ...module.globals },
                    (...args) => this.exec(name, ...args)
                );
            } else {
                state.queue.forEach(([event, properties, globals]) => {
                    module.handler(event, properties, globals, (...args) =>
                        this.exec(name, ...args)
                    );
                });
            }
            state.queue = [];
            clearTimeout(state.timerId);
            state.timerId = null;
        }
    }

    flushAll() {
        this.modules.forEach((module) => this.flush(module));
    }
}

export default BatchHarvester;
