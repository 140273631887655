import { ImHome } from 'react-icons/im';
import { useNavigate } from 'react-router-dom';

const SimpleHeader: React.FC = () => {
    const navigate = useNavigate();

    return (
        <div className="flex w-full items-center border-[1px] border-stone-300 bg-stone-100">
            <div
                className="h-full cursor-pointer border-r-[1px] border-stone-300 p-6"
                onClick={() => navigate('/')}
            >
                <ImHome className="text-xl text-stone-500" />
            </div>
        </div>
    );
};

export default SimpleHeader;
