import React, { useContext } from 'react';
import ReactDOM from 'react-dom/client';
// import './styles/reset.css';
//Tailwind CSS file generated from input.css
import './index.css';
import reportWebVitals from './reportWebVitals';
import { AuthContextProvider } from './context/auth-context';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './pages/Home/Home';
import TermsOfUse from './pages/terms-of-use';
import PrivacyPolicy from './pages/privacy-policy';
import SearchNewVehicles from './pages/SearchNewVehicles/SearchNewVehicles';
import { UsedVehicleContextProvider } from './context/used-vehicle-context';
import SearchUsedVehicles from './pages/SearchUsedVehicles/SearchUsedVehicles';
import { ABTestingContextProvider } from './context/ab-testing-context';

//Harvester Imports
import mixpanel from 'mixpanel-browser';
import { Provider as HarvestProvider } from '../src/helpers/harvester/react/Provider';
import { ClientJS } from 'clientjs';
import { string } from 'zod';

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

const harvestConfig = {
    globals: {
        cid: new ClientJS().getFingerprint(),
        variant: undefined,
    },
    modules: {
        dtc: {
            globals: {
                api: process.env.REACT_APP_CPI_PROXY_SERVER + `/harvester`,
            },
            init: ({ ...globals }) => {
                // console.warn('DTC initialized');
            },
            handler: (event: any, properties: any, globals: any, exec: any) => {
                exec('upload', [[event, properties]]);
            },
            batch: (events: any, globals: any, exec: any) => {
                exec('upload', events);
            },
            upload({ api, cid }: any, events: any) {
                // console.log(`[DTC]:`, events, cid);
                fetch(`${api}/harvest`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'X-DTC-Client-ID': cid,
                    },
                    keepalive: true,
                    body: JSON.stringify(events),
                })
                    .then((response) => response.json())
                    .then((data) => {
                        // console.log('Success:', data);
                    })
                    .catch((error) => {
                        // console.error('Error:', error);
                    });
            },
        },
        mixpanel: {
            globals: {
                token: `f434a03c18708f0ec741f8e74db7f61c`,
            },
            init: ({ token, cid }: any) => {
                mixpanel.init(token); // {debug: true} provides console output
                mixpanel.identify(cid);
                // console.warn('MixPanel initialized');
            },
            handler: (event: any, payload: any, { ...globals }) => {
                // console.log(`[MixPanel]:`, event, payload);
                mixpanel.track(event, payload);
            },
        },
    },
};

// const App = () => {
//     const { harvester } = useContext(HarvesterContext);
//     const handleBeforeUnload = (event: any) => {
//         alert('test');
//         userSessionEnd(event);
//     };
//     window.addEventListener('beforeunload', handleBeforeUnload);

root.render(
    <React.StrictMode>
        <HarvestProvider batching={true} config={harvestConfig}>
            <BrowserRouter>
                <AuthContextProvider>
                    <UsedVehicleContextProvider>
                        <ABTestingContextProvider>
                            {/* <App /> */}
                            <Routes>
                                <Route path="/" element={<Home />} />
                                <Route
                                    path="/used_car_search"
                                    element={<SearchUsedVehicles />}
                                />
                                <Route
                                    path="/new_vehicle_pricing"
                                    element={<SearchNewVehicles />}
                                />
                                <Route
                                    path="/terms-of-use"
                                    element={<TermsOfUse />}
                                />
                                <Route
                                    path="/privacy-policy"
                                    element={<PrivacyPolicy />}
                                />
                            </Routes>
                        </ABTestingContextProvider>
                    </UsedVehicleContextProvider>
                </AuthContextProvider>
            </BrowserRouter>
        </HarvestProvider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
