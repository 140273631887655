import React, { useState, useEffect } from 'react';
import { FaArrowCircleUp } from 'react-icons/fa';

const ScrollToTop: React.FC = () => {
    const [isVisible, setIsVisible] = useState(false);
    useEffect(() => {
        const toggleVisibility = () => {
            if (window.pageYOffset > 300) {
                setIsVisible(true);
            } else {
                setIsVisible(false);
            }
        };
        window.addEventListener('scroll', toggleVisibility);
        return () => window.removeEventListener('scroll', toggleVisibility);
    }, []);
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };
    return (
        <button
            className={
                'fixed bottom-[6.25rem] right-5 h-[50px] w-[50px] cursor-pointer items-center justify-center rounded-full border-none bg-[#0099ff8c] text-white outline-none hover:opacity-80 hover:transition-opacity' +
                (isVisible ? ' flex' : ' hidden')
            }
            onClick={scrollToTop}
        >
            <FaArrowCircleUp />
        </button>
    );
};

export default ScrollToTop;
