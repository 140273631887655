import { BsSliders2 } from 'react-icons/bs';
import { AiFillCaretDown, AiFillHome } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';
import { ImHome } from 'react-icons/im';
import { IoCarSportSharp } from 'react-icons/io5';
import { Disclosure, Menu, Transition } from '@headlessui/react';
import { bodyOptions } from '../pages/SearchUsedVehicles/SearchUsedVehiclesScripts';
import { useContext, useEffect, useState } from 'react';
import { UsedVehicleContext } from '../context/used-vehicle-context';
import { inventoryCall } from '../helpers/getInventory';

type SrpHeaderProps = {
    openCart: () => void;
    loader: (state: boolean) => void;
    setIsFilterOpen: (state: boolean) => void;
};

const SrpHeader: React.FC<SrpHeaderProps> = (props: SrpHeaderProps) => {
    const { usedVehicleState, updateUsedVehicleState } =
        useContext(UsedVehicleContext);
    const {
        selectedKeys,
        selectedBodyTypes,
        selectedBodyTypeValues,
        searchQueryParams,
        excludeKeys,
    } = usedVehicleState;
    const navigate = useNavigate();

    const bodyFilterHandler = async (bodyType: string) => {
        props.loader(true);
        const size = 50;
        const bodyOptionsCopy = [ ...bodyOptions ];
        const bodyTypes = [ ...selectedBodyTypes ];
        const bodyTypeValues = [ ...selectedBodyTypeValues ];
        const queryParams = { ...searchQueryParams };
        queryParams.size = size;
        bodyOptionsCopy.forEach((bodyOption) => {
            if (bodyOption.display === bodyType) {
                bodyOption.bodyTypes.map((values) => {
                    if (bodyTypeValues.includes(values)) {
                        bodyTypeValues.splice(
                            bodyTypeValues.indexOf(values),
                            1
                        );
                    } else {
                        bodyTypeValues.push(values);
                    }
                });
            }
        });
        if (bodyTypes.includes(bodyType)) {
            bodyTypes.splice(bodyTypes.indexOf(bodyType), 1);
        } else {
            bodyTypes.push(bodyType);
        }
        const inventory = await inventoryCall(
            queryParams,
            bodyTypeValues,
            excludeKeys
        );
        if (inventory && inventory.facets && inventory.hits) {
            const vehicleCount = inventory.hits.found;
            updateUsedVehicleState({
                ...usedVehicleState,
                bodyCounts: vehicleCount,
                selectedBodyTypes: bodyTypes,
                selectedBodyTypeValues: bodyTypeValues,
                searchQueryParams: queryParams,
                vehicleInventory: inventory.hits.hit,
            });
        }
        props.loader(false);
    };

    const cartHandler = () => {
        props.openCart();
    };

    return (
        <div className="sticky left-0 top-0 z-50 flex h-12 w-full items-center border-b-[1px] border-stone-300 bg-white">
            <Disclosure
                as={ 'div' }
                className="relative ml-auto flex h-full w-full items-center justify-between"
            >
                {
                    ({ open, close }) => {
                        props.setIsFilterOpen(open);

                        return (
                            <>
                                <div className="relative left-0 top-0 flex h-12 items-center">
                                    <div
                                        onClick={ () => navigate('/') }
                                        className="box-border flex h-full w-12 cursor-pointer items-center justify-center border-r-[1px] border-stone-300"
                                    >
                                        <ImHome className="text-xl text-stone-500" />
                                    </div>
                                    <div
                                        onClick={ () => cartHandler() }
                                        className="box-border flex h-full w-20 cursor-pointer items-center justify-center border-r-[1px] border-stone-300 px-3"
                                    >
                                        <IoCarSportSharp className="mr-1 text-xl text-stone-500" />
                                        { selectedKeys.length }/5
                                    </div>
                                </div>
                                <Disclosure.Button className="relative flex items-center px-3 pr-2">
                                    <BsSliders2 className="mr-[7px] text-stone-500" />
                                    <p className="text-stone-700">{ open ? `Hide` : `Show` } Filters</p>
                                    <AiFillCaretDown className="ml-1 h-auto w-auto p-1 text-stone-400 duration-100 ease-in-out ui-open:rotate-180" />
                                </Disclosure.Button>
                                <Transition
                                    show={ open }
                                    className="absolute right-0 top-full w-full"
                                    enter="transition duration-100 ease-out"
                                    enterFrom="transform scale-95 opacity-0"
                                    enterTo="transform scale-100 opacity-100"
                                    leave="transition duration-75 ease-out"
                                    leaveFrom="transform scale-100 opacity-100"
                                    leaveTo="transform scale-95 opacity-0"
                                >
                                    <Disclosure.Panel className="absolute right-0 top-full w-full border-y-[1px] border-stone-300 bg-white">
                                        <div className="w-full p-4 text-xs text-stone-700">
                                            <p>Filter by Vehicle</p>
                                            <div className="my-4 flex w-full flex-wrap items-center justify-center gap-y-5">
                                                { bodyOptions.map((bodyType) => {
                                                    return (
                                                        <label
                                                            key={ bodyType.display }
                                                            className="flex w-1/2 items-center"
                                                        >
                                                            <input
                                                                className="form-checkbox mr-[11px] box-border h-[1.125rem] w-[1.125rem] rounded-sm border-2 border-stone-500"
                                                                type="checkbox"
                                                                checked={ selectedBodyTypes.includes(
                                                                    bodyType.display
                                                                ) }
                                                                onChange={ (e) =>
                                                                    bodyFilterHandler(
                                                                        e.currentTarget.value
                                                                    )
                                                                }
                                                                value={ bodyType.display }
                                                            />
                                                            <p>{ bodyType.display }</p>
                                                        </label>
                                                    );
                                                }) }
                                            </div>
                                            <p className="text-xs text-stone-500">
                                                Currently showing{ ' ' }
                                                { selectedBodyTypes.length === 4 ||
                                                    selectedBodyTypes.length === 0
                                                    ? 'all body types'
                                                    : selectedBodyTypes.join(', ') }
                                            </p>
                                        </div>
                                        <Disclosure.Button
                                            as="div"
                                            className="absolute top-full z-[10] h-screen w-full cursor-default bg-transparent backdrop-blur-md"
                                            onBlur={ () => close() }
                                        />
                                    </Disclosure.Panel>
                                </Transition>
                            </>
                        )
                    } }
            </Disclosure>
        </div>
    );
};

export default SrpHeader;
