import { useContext, useEffect, useState } from 'react';
import SrpFooter from '../../components/SrpFooter';
import SrpHeader from '../../components/SrpHeader';
import SrpLayout from '../../components/SrpLayout';
import { UsedVehicleContext } from '../../context/used-vehicle-context';
import VehicleDetailCard from '../../components/VehicleDetailCard';
import { ImSpinner2 } from 'react-icons/im';
import { paginateInventory } from './SearchUsedVehiclesScripts';
import { inventoryCall } from '../../helpers/getInventory';
import { useNavigate } from 'react-router-dom';
import ScrollToTop from '../../components/ScrollToTop';
import FormDialog from '../../components/dialogs/FormDialog';
import { Context as HarvesterContext } from '../../helpers/harvester/react/Provider';
import { itFormEntranceClick } from '../../helpers/harvester-events';
import { gaFormEnteranceClick } from '../../helpers/google-events';
import { ABTestingContext } from '../../context/ab-testing-context';
import CartDialog from '../../components/dialogs/CartDialog';
import useScrollToTop from '../../hooks/autoScrollToTop';

const SearchUsedVehicles: React.FC = () => {
    //Context Vars
    const { harvester } = useContext(HarvesterContext);
    const { usedVehicleState, updateUsedVehicleState } =
        useContext(UsedVehicleContext);
    const {
        vehicleInventory,
        bodyCounts,
        searchQueryParams,
        selectedBodyTypeValues,
        excludeKeys,
    } = usedVehicleState;
    const { price } = searchQueryParams;
    const { abTestingState } = useContext(ABTestingContext);
    const { variant } = abTestingState;
    //State Vars
    const [ cardLoading, setCardLoading ] = useState<boolean>(false);
    const [ formOpen, setFormOpen ] = useState<boolean>(false);
    const [ cartOpen, setCartOpen ] = useState<boolean>(false);
    //Other Vars
    const navigate = useNavigate();

    //Navigate to home if no inventory
    useEffect(() => {
        if (!vehicleInventory.length || !bodyCounts) {
            navigate('/');
        }
    }, []);

    //Start Body Filter Handlers
    const bodyFilterLoader = (state: boolean) => {
        setCardLoading(state);
    };
    //End Body Filter Handlers

    //Start Card Handlers
    const vehicleCards = () => {
        const Cards = paginateInventory(vehicleInventory);
        const paginatedCards = Cards.map((vehicle: any) => {
            const {
                vehicleId,
                vehiclePicture,
                vehicleYear,
                vehicleMake,
                vehicleModel,
                vehiclePrice,
                vehicleMileage,
                vehicleLocation,
                distance,
                vehicleVin,
                checkKey,
                scrollKey,
            } = vehicle;
            return (
                <VehicleDetailCard
                    key={ vehicleId }
                    vehiclePicture={ vehiclePicture }
                    vehicleYear={ vehicleYear }
                    vehicleMake={ vehicleMake }
                    vehicleModel={ vehicleModel }
                    vehiclePrice={ vehiclePrice }
                    vehicleMileage={ vehicleMileage }
                    vehicleLocation={ vehicleLocation }
                    distance={ distance }
                    vehicleVin={ vehicleVin }
                    checkKey={ checkKey }
                    scrollKey={ scrollKey }
                    onEnter={ () => {
                        scrollTrigger(scrollKey);
                    } }
                />
            );
        });
        return paginatedCards;
    };

    const scrollTrigger = async (scrollKey: string) => {
        const queryParams = { ...searchQueryParams };
        const bodyTypes = selectedBodyTypeValues;
        let currentSize = queryParams.size;
        const sizeCheck = Boolean(
            (Number(scrollKey) + 1) * 50 === vehicleInventory.length
        );
        if (sizeCheck) {
            currentSize += 50;
            queryParams.size = currentSize;

            const inventory = await inventoryCall(
                queryParams,
                bodyTypes,
                excludeKeys
            );
            updateUsedVehicleState({
                ...usedVehicleState,
                searchQueryParams: queryParams,
                vehicleInventory: inventory.hits.hit,
            });
        }
    };
    //End Card Handlers

    //Start Form Handlers
    const formOpenHandler = () => {
        if (!formOpen) {
            gaFormEnteranceClick();
            itFormEntranceClick(harvester);
        }
        setFormOpen(!formOpen);
    };
    //End Form Handlers

    //Start Cart Handlers
    const cartOpenHandler = () => {
        setCartOpen(!cartOpen);
    };
    //End Cart Handlers

    return (
        <SrpLayout pageTitle="Shop Used Cars">
            { ({ setIsFilterOpen }) => (
                <>
                    <SrpHeader
                        loader={ bodyFilterLoader }
                        openCart={ () => cartOpenHandler() }
                        setIsFilterOpen={ setIsFilterOpen }
                    />
                    {
                        cardLoading ? (
                            <div className="flex h-screen w-full items-center justify-center">
                                <ImSpinner2 className="animate-spin text-9xl text-blue-500" />
                            </div>
                        ) : (
                            <div className="mx-auto mb-[4.5rem] flex min-w-[17rem] max-w-[100vw] flex-col gap-y-6 p-6">
                                <div>
                                    <p className="text-sm font-normal">
                                        We've found{ ' ' }
                                        <b>{ bodyCounts && bodyCounts.toLocaleString() }</b>{ ' ' }
                                        deals under&nbsp;
                                        <sup>
                                            <b>$</b>
                                        </sup>
                                        { price[ 1 ].toLocaleString() }. Select up to 3 vehicles
                                        to be put in touch with the respective dealers.
                                    </p>
                                </div>
                                <div className="mb-6 flex flex-col items-center gap-y-6 md:w-auto md:flex-row md:flex-wrap md:justify-center md:gap-x-6">
                                    { vehicleCards() }
                                    <ScrollToTop />
                                </div>
                            </div>
                        )
                    }
                    <SrpFooter formOpen={ () => formOpenHandler() } />
                    <FormDialog
                        variant={ variant ?? 'A' }
                        isOpen={ formOpen }
                        closeForm={ () => formOpenHandler() }
                        closeCart={ () => cartOpenHandler() }
                    />
                    <CartDialog
                        isOpen={ cartOpen }
                        closeCart={ () => cartOpenHandler() }
                        formOpen={ () => formOpenHandler() }
                    />
                </>
            ) }
        </SrpLayout>
    );
};

export default SearchUsedVehicles;
