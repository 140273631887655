import { Dialog } from '@headlessui/react';
import Button from '../Button';

type VehicleLimitDialogProps = {
    isOpen: boolean;
    closeDialog: () => void;
};

const VehicleLimitDialog: React.FC<VehicleLimitDialogProps> = (
    props: VehicleLimitDialogProps
) => {
    return (
        <Dialog
            as="div"
            open={props.isOpen}
            onClose={props.closeDialog}
            className="fixed top-0 z-[113] flex h-screen w-screen items-center justify-center bg-stone-700/20 p-6 backdrop-blur-sm"
        >
            <Dialog.Panel as="div" className="rounded bg-white p-6 shadow-card">
                <p className="mb-4 text-center">
                    A maximum of 5 vehicles may be selected.
                </p>
                <Button className="h-12 w-full" onClick={props.closeDialog}>
                    Close
                </Button>
            </Dialog.Panel>
        </Dialog>
    );
};

export default VehicleLimitDialog;
