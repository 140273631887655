import React, { useContext, useEffect, useState } from 'react';
import CurrencyInput from '../../components/CurrencyInput';
import ReactSlider from 'react-slider';
import FormSelect from '../../components/FormSelect';
import InterestInput from '../../components/InterestInput';
import HomeLayout from '../../components/HomeLayout';
import Button from '../../components/Button';
import { useNavigate } from 'react-router-dom';
import {
    getInterestRate,
    getMonthlyPayments,
    interestRateDisclaimer,
    ios,
    legalVerbiage,
    loanTerms,
    scores,
} from './HomeScripts';
import ZipCodeInput from '../../components/ZipCodeInput';
import {
    getLatLong,
    inventoryCall,
    latLongToZip,
    zipToLatLong,
} from '../../helpers/getInventory';
import formatThousands from '../../helpers/formatThousands';
import { UsedVehicleContext } from '../../context/used-vehicle-context';
import { Context as HarvesterContext } from '../../helpers/harvester/react/Provider';
import Footer from '../../components/Footer';
import { ImSpinner2 } from 'react-icons/im';
import MonthlyPayment from '../../components/MonthlyPayment';
import { Values } from 'react-currency-format';
import {
    cpiCreditScore,
    cpiGeolocationLookupClick,
    cpiInterestRate,
    cpiLoanAmount,
    cpiLoanAmountSlider,
    cpiShopNewClick,
    cpiShopUsedClick,
    cpiZipCode,
} from '../../helpers/harvester-events';
import useScrollToTop from '../../hooks/autoScrollToTop';

const Home: React.FC = () => {
    const { harvester } = useContext(HarvesterContext);
    const { usedVehicleState, updateUsedVehicleState } =
        useContext(UsedVehicleContext);
    const { searchQueryParams, bodyCounts, excludeKeys } = usedVehicleState;
    const { zip, price } = searchQueryParams;
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [creditScoreState, setCreditScoreState] = useState<string>('680-739');
    const [interestRateState, setInterestRateState] = useState<number | string>(
        7.83
    );
    const [monthlyPayments, setMonthlyPayments] = useState<Array<number>>(
        getMonthlyPayments(price[1], Number(interestRateState))
    );
    const [loanAmountError, setLoanAmountError] = useState({
        valid: false,
        errors: {},
    });
    const [interestRateError, setInterestRateError] = useState({
        valid: false,
        errors: {},
    });
    const [zipCodeError, setZipCodeError] = useState({
        valid: false,
        errors: {},
    });

    //Set Slider Track Colors
    useEffect(() => {
        const leftTrack = document.querySelector('div.track-0');
        const rightTrack = document.querySelector('div.track-1');
        leftTrack && leftTrack.classList.add('bg-blue-500');
        rightTrack && rightTrack.classList.add('bg-blue-500/50');
        if (zip) {
            locationHandler(zip);
        }
    }, []);

    //Start Loan Amount Handlers
    const loanAmountHandler = (value: string) => {
        setLoanAmountError({
            valid: false,
            errors: {},
        });
        const loanAmount = value;
        if (zip && zip.length === 5) {
            locationHandler(undefined, loanAmount);
        } else {
            const queryParams = {
                ...searchQueryParams,
                price: [5000, Number(loanAmount)],
            };
            updateUsedVehicleState({
                ...usedVehicleState,
                searchQueryParams: queryParams,
            });
        }
        const loanTerms = getMonthlyPayments(
            Number(loanAmount),
            Number(interestRateState)
        );
        setMonthlyPayments(loanTerms);
    };

    const sliderHandler = async (value: number) => {
        setLoanAmountError({
            valid: false,
            errors: {},
        });
        const loanAmount = value;
        cpiLoanAmountSlider(loanAmount, harvester);
        if (zip && zip.length === 5) {
            await locationHandler(undefined, loanAmount.toString());
        } else {
            const queryParams = {
                ...searchQueryParams,
                price: [5000, loanAmount],
            };
            updateUsedVehicleState({
                ...usedVehicleState,
                searchQueryParams: queryParams,
            });
        }
        const loanTerms = getMonthlyPayments(
            loanAmount,
            Number(interestRateState)
        );
        setMonthlyPayments(loanTerms);
    };
    //End Loan Amount Handlers

    //Start Credit Score & Interest Rate Handlers
    const creditInterestHandler = (
        type: 'credit' | 'interest',
        target: any
    ) => {
        let interestRate;
        if (type === 'credit') {
            const creditScore = target.value;
            interestRate = getInterestRate(creditScore);
            if (interestRate !== undefined) {
                cpiCreditScore(creditScore, harvester);
                setInterestRateState(interestRate);
                setCreditScoreState(creditScore);
                interestRate === 0 &&
                    !ios() &&
                    document.getElementById('interestInput')?.focus();
            }
        } else {
            interestRate = target;
            setInterestRateState(interestRate);
        }
        if (interestRate !== undefined) {
            const loanTerms = getMonthlyPayments(price[1], interestRate);
            setMonthlyPayments(loanTerms);
        }
    };

    const interestRateLimiter = (values: Values) => {
        const value = Number(values.value);
        if (value < 100) {
            setInterestRateError({ valid: false, errors: {} });
            return true;
        } else {
            const errors: any = {};
            errors['interestRate'] =
                'Please enter an interest rate lower than 100%';
            setInterestRateError({
                valid: true,
                errors: errors,
            });
            return false;
        }
    };
    //End Credit Score & Interest Rate Handlers

    //Start Zip Code Handlers
    const locationHandler = async (
        zip?: string,
        loanAmount?: string,
        button?: boolean
    ) => {
        setIsLoading(true);
        setZipCodeError({ valid: false, errors: {} });
        let queryParams;
        let cityState = { city: undefined, state: undefined };
        if (loanAmount) {
            queryParams = {
                ...searchQueryParams,
                price: [5000, Number(loanAmount)],
            };
        } else if (zip) {
            const locationData = await zipToLatLong(zip);
            if (locationData) {
                cityState = {
                    city: locationData.city,
                    state: locationData.state,
                };
                const latLong = {
                    lat: locationData.latLong.lat,
                    long: locationData.latLong.long,
                };
                queryParams = {
                    ...searchQueryParams,
                    zip: zip,
                    lat: latLong.lat,
                    long: latLong.long,
                };
            }
        } else {
            const latLong = await getLatLong();
            if (latLong) {
                button && cpiGeolocationLookupClick(latLong, harvester);
                const locationData = await latLongToZip(latLong);
                if (locationData) {
                    cityState = {
                        city: locationData.city,
                        state: locationData.state,
                    };
                    queryParams = {
                        ...searchQueryParams,
                        zip: locationData.zip,
                        lat: locationData.latitude,
                        long: locationData.longitude,
                    };
                }
            } else {
                alert(
                    'Unable to retrieve Zip Code. Please enable location permissions in your browser settings.'
                );
            }
        }
        if (queryParams) {
            const inventory = await inventoryCall(
                queryParams,
                undefined,
                excludeKeys
            );
            if (inventory && inventory.facets && inventory.hits) {
                const vehicleCount = inventory.hits.found;
                updateUsedVehicleState({
                    ...usedVehicleState,
                    bodyCounts: vehicleCount,
                    searchQueryParams: queryParams,
                    vehicleInventory: inventory.hits.hit,
                    city: cityState.city ?? usedVehicleState.city,
                    state: cityState.state ?? usedVehicleState.state,
                });
                setIsLoading(false);
                return true;
            } else {
                setIsLoading(false);
                return false;
            }
        } else {
            setIsLoading(false);
            return false;
        }
    };

    const zipCodeLimiter = (value: string) => {
        if (value.length < 5) {
            setZipCodeError({ valid: false, errors: {} });
            return true;
        } else if (value.length == 5) {
            cpiZipCode(value, harvester);
            locationHandler(value);
            return true;
        } else {
            return false;
        }
    };
    //End Zip Code Handlers

    //Start Monthly Payment Components
    const MonthlyPaymentComponents = loanTerms.map((placeholder, index) => {
        return (
            <MonthlyPayment
                key={index}
                termLength={loanTerms[index].value}
                payment={interestRateState == 0 ? 0 : monthlyPayments[index]}
            />
        );
    });
    //End Monthly Payment Components

    //Start Shop Button Handlers
    const handleNewSubmit = () => {
        cpiShopNewClick(harvester);
        navigate(`/new_vehicle_pricing${zip ? '?zipCode=' + zip : ''}`);
    };

    const handleUsedCarSubmit = async () => {
        let loanSubmissionValid = false;
        let zipSubmissionValid = false;
        if (!price[1]) {
            const errors: any = {};
            errors['loanAmount'] = 'Please enter a loan amount';
            setLoanAmountError({
                valid: true,
                errors: errors,
            });
            loanSubmissionValid = false;
        } else {
            loanSubmissionValid = true;
        }
        if (zip === undefined || zip?.length < 5) {
            zipSubmissionValid = await locationHandler();
            if (!zipSubmissionValid) {
                const errors: any = {};
                errors['zipCode'] = 'Please enter a ZIP Code';
                setZipCodeError({
                    valid: true,
                    errors: errors,
                });
            }
        } else {
            zipSubmissionValid = true;
        }
        if (loanSubmissionValid && zipSubmissionValid) {
            setZipCodeError({ valid: false, errors: {} });
            setLoanAmountError({ valid: false, errors: {} });
            cpiShopUsedClick(harvester);
            navigate(`/used_car_search`);
        } else {
            setIsLoading(false);
        }
    };
    //End Shop Button Handlers

    useScrollToTop();

    return (
        <HomeLayout>
            <h3 className="mb-4 w-full text-left text-xl font-bold text-stone-700 sm:text-2xl lg:mb-6 lg:text-4xl">
                Car Payment Calculator
            </h3>
            {/* FullContainer */}
            <div className="flex w-full flex-col justify-center rounded border-stone-300 bg-white p-0 max-sm:items-center sm:justify-start sm:border-b-[1px] sm:max-lg:flex-row">
                {/* InputContainer */}
                <div className="flex w-full flex-col justify-between border-b-[1px] border-stone-300 max-lg:p-4 sm:max-lg:w-[263px] sm:max-lg:border-b-0 sm:max-lg:border-r-[1px] lg:flex-row">
                    {/* <div className="lg:mb-1.5rem mb-[1.5625rem] flex h-auto w-full flex-col justify-center lg:h-[unset]"> */}
                    <div className="lg:border-r-[1px] lg:border-stone-300 lg:p-4">
                        <CurrencyInput
                            onValueChange={(values: {
                                formattedValue: any;
                                value: any;
                            }) => {
                                const { value } = values;
                                loanAmountHandler(value);
                            }}
                            title="Loan Amount"
                            value={price[1]}
                            onClick={() => {
                                const queryParams = {
                                    ...searchQueryParams,
                                    price: [5000, 0],
                                };
                                updateUsedVehicleState({
                                    ...usedVehicleState,
                                    searchQueryParams: queryParams,
                                });
                            }}
                            tabIndex={1}
                            onBlur={(e) => {
                                cpiLoanAmount(e.target, harvester);
                            }}
                            errorMessage={
                                loanAmountError.valid
                                    ? (loanAmountError.errors as any)
                                          ?.loanAmount
                                    : undefined
                            }
                        />
                        <ReactSlider
                            ariaLabel="Loan Amount Slider"
                            className="mx-auto my-3 h-[20px]"
                            trackClassName={'h-[4px] rounded-full track'}
                            thumbClassName={
                                'w-[20px] h-[20px] rounded-full cursor-pointer outline-none bg-blue-500'
                            }
                            min={5000}
                            max={100000}
                            marks={[5000, 100000]}
                            value={price[1]}
                            onChange={(value) => {
                                const roundedAmount =
                                    Math.ceil(value / 1000) * 1000;
                                setLoanAmountError({
                                    valid: false,
                                    errors: {},
                                });
                                const queryParams = {
                                    ...searchQueryParams,
                                    price: [5000, roundedAmount],
                                };
                                updateUsedVehicleState({
                                    ...usedVehicleState,
                                    searchQueryParams: queryParams,
                                });
                            }}
                            onAfterChange={(value) => {
                                const roundedAmount =
                                    Math.ceil(value / 1000) * 1000;
                                sliderHandler(roundedAmount);
                            }}
                        />
                    </div>
                    <div className="mt-2 flex flex-col max-lg:w-full max-lg:items-end max-lg:justify-center lg:mt-0 lg:flex-row">
                        <div className="flex w-full flex-col justify-start max-lg:mr-auto lg:mb-auto lg:w-[249px] lg:p-4">
                            <FormSelect
                                title="Approximate Credit Score*"
                                tabIndex={2}
                                options={scores}
                                value={creditScoreState}
                                onChange={(e) => {
                                    creditInterestHandler('credit', e.target);
                                }}
                                className="h-12 py-3"
                            />
                        </div>
                        <div className="flex w-full flex-row gap-4 max-lg:my-4 lg:mb-auto lg:w-[231px] lg:py-4">
                            <InterestInput
                                title="Interest Rate"
                                id="interestInput"
                                errorMessage={
                                    interestRateError.valid
                                        ? (interestRateError.errors as any)
                                              ?.interestRate
                                        : undefined
                                }
                                onBlur={(e) => {
                                    cpiInterestRate(
                                        (e.target as any).value,
                                        harvester
                                    );
                                    if ((e.target as any).value == '') {
                                        (e.target as any).value =
                                            interestRateState;
                                    }
                                }}
                                onClick={() => {
                                    setCreditScoreState('0');
                                    setInterestRateState('');
                                }}
                                value={interestRateState}
                                onInput={() => {
                                    setCreditScoreState('0');
                                    document.addEventListener(
                                        'keydown',
                                        (event) => {
                                            if (
                                                event.key == 'Enter' ||
                                                event.keyCode == 13
                                            ) {
                                                document
                                                    .getElementById(
                                                        'interestInput'
                                                    )
                                                    ?.blur();
                                            }
                                        }
                                    );
                                }}
                                onValueChange={(values: {
                                    formattedValue: any;
                                    value: any;
                                }) => {
                                    const { value } = values;
                                    creditInterestHandler('interest', value);
                                }}
                                isAllowed={(values) =>
                                    interestRateLimiter(values)
                                }
                                tabIndex={3}
                            />
                            <ZipCodeInput
                                title="Zip Code"
                                errorMessage={
                                    zipCodeError.valid
                                        ? (zipCodeError.errors as any)?.zipCode
                                        : undefined
                                }
                                value={zip ? zip : undefined}
                                onValueChange={(e) => {
                                    const queryParams = {
                                        ...searchQueryParams,
                                    };
                                    queryParams.zip = e.value;
                                    updateUsedVehicleState({
                                        ...usedVehicleState,
                                        searchQueryParams: queryParams,
                                    });
                                }}
                                isAllowed={(e) => zipCodeLimiter(e.value)}
                                getLocation={() =>
                                    locationHandler(undefined, undefined, true)
                                }
                            />
                        </div>
                    </div>
                    {/* {interestRateError.valid && (
                            // <p className="pErrorMessage d-block d-lg-none"></p>
                            <p className="block text-center text-red-500">
                                {(
                                    interestRateError.errors as any
                                )?.interestRate.toString()}
                            </p>
                        )} */}
                    <div className="flex w-full flex-row items-end justify-center lg:hidden">
                        <p className="text-sm text-stone-700">
                            {isLoading ? (
                                <ImSpinner2 className="animate-spin" />
                            ) : bodyCounts && zip?.length === 5 ? (
                                <>
                                    {formatThousands(bodyCounts)}
                                    &nbsp;Vehicles available near&nbsp;
                                    {zip}
                                </>
                            ) : (
                                <>Enter postal code to see inventory</>
                            )}
                        </p>
                    </div>
                    <div>
                        <div className="mt-4 flex flex-row justify-center gap-4 lg:mt-5 lg:w-[272px] lg:p-4">
                            <Button
                                className="w-full py-3"
                                onClick={() => handleNewSubmit()}
                            >
                                Shop New
                            </Button>
                            <Button
                                className="w-full py-3"
                                onClick={() => {
                                    handleUsedCarSubmit();
                                }}
                            >
                                Shop Used
                            </Button>
                        </div>
                        <div className="flex w-full flex-row items-end justify-center max-lg:hidden">
                            <p className="text-sm text-stone-700">
                                {isLoading ? (
                                    <ImSpinner2 className="animate-spin" />
                                ) : bodyCounts && zip?.length === 5 ? (
                                    <>
                                        {formatThousands(bodyCounts)}
                                        &nbsp;Vehicles available near&nbsp;
                                        {zip}
                                    </>
                                ) : (
                                    <>Enter postal code to see inventory</>
                                )}
                            </p>
                        </div>
                    </div>
                </div>
                {/* Monthly Payments */}
                <div className="flex w-full justify-between rounded-b border-b-[1px] border-stone-300 max-lg:flex-col sm:border-b-0 sm:max-lg:w-[152px]">
                    <div className="flex w-full justify-between py-3 pl-4 text-base font-bold text-stone-700 lg:w-[117px] lg:px-6 lg:py-5">
                        <p className="mb-0 sm:leading-5">Monthly Payment</p>
                    </div>
                    <div className="mb-0 flex h-full w-full border-collapse flex-col overflow-hidden rounded-b lg:flex-row">
                        {MonthlyPaymentComponents}
                    </div>
                </div>
            </div>
            <div className="my-4 flex h-auto justify-center">
                <p className="mb-0 w-full text-xs font-normal text-stone-700">
                    {interestRateDisclaimer}&nbsp;
                    {legalVerbiage}
                </p>
            </div>
            <Footer />
        </HomeLayout>
    );
};

export default Home;
