const formatThousands = (props: string) => {
    if (props.length > 6) {
        return (
            props.slice(0, -6) +
            ',' +
            props.slice(-6, -3) +
            ',' +
            props.slice(-3)
        );
    } else if (props.length > 3) {
        return props.slice(0, -3) + ',' + props.slice(-3);
    } else {
        return props;
    }
};

export default formatThousands;
