export const interestRateDisclaimer =
    '*Interest rate estimate based on your credit score.';

export const legalVerbiage =
    'The information provided is for illustrative purposes only and is not an offer to lend.  Source for credit range and rate information: Experian Q4 2022 State of Automotive Finance Report, for used vehicles.';

export const scores = [
    { value: '740-850', valueDisplay: 'Excellent (740-850)' },
    { value: '680-739', valueDisplay: 'Good (680-739)' },
    { value: '630-679', valueDisplay: 'Fair (630-679)' },
    { value: '560-629', valueDisplay: 'Poor (560-629)' },
    { value: '300-559', valueDisplay: 'Very Poor (300-559)' },
    { value: '0', valueDisplay: 'Custom' },
];

export const loanTerms = [
    { value: 84 },
    { value: 72 },
    { value: 60 },
    { value: 48 },
    { value: 36 },
];

export const interestRates = [5.99, 7.83, 12.08, 17.46, 20.62];

export const getInterestRate = (creditScore: any) => {
    let interestRate;
    switch (creditScore) {
        case '740-850':
            interestRate = 5.99;
            break;
        case '680-739':
            interestRate = 7.83;
            break;
        case '630-679':
            interestRate = 12.08;
            break;
        case '560-629':
            interestRate = 17.46;
            break;
        case '300-559':
            interestRate = 20.62;
            break;
        case '0':
            interestRate = 0;
            break;
    }
    return interestRate;
};

export const getMonthlyPayments = (
    loanAmount: number,
    interestRate: number
) => {
    const monthlyPayments: Array<number> = [];
    const monthlyInterest = interestRate / 100 / 12;
    loanTerms.map((term) => {
        const termLength = term.value;
        const paymentPerMonth = Math.ceil(
            (monthlyInterest /
                (1 - Math.pow(1 + monthlyInterest, -termLength))) *
                loanAmount
        );
        monthlyPayments.push(paymentPerMonth);
    });
    return monthlyPayments;
};

export const ios = () => {
    if (typeof window === 'undefined' || typeof navigator === 'undefined')
        return false;
    return /iPhone|iPad|iPod/i.test(navigator.userAgent || navigator.vendor);
};
