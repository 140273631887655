import axios from 'axios';
import { UsedVehicleState } from '../../context/used-vehicle-context';

export const bodyOptions = [
    { display: 'SUV', bodyTypes: ['SUV'] },
    { display: 'Pickup', bodyTypes: ['Pickup'] },
    {
        display: 'Sedan',
        bodyTypes: ['Sedan', 'Convertible', 'Coupe', 'Full-Size'],
    },
    { display: 'Wagon', bodyTypes: ['Wagon', 'Hatchback', 'Mini-Van'] },
];

export const paginateInventory = (inventory: Array<object>) => {
    let cardIterator = 0;
    let scrollTriggerIterator = 0;
    const pagination: Array<object> = [];
    inventory.map((vehicleData: any) => {
        let scrollTrigger = false;
        const scrollKey = scrollTriggerIterator;
        const cardNumber = cardIterator;
        const fields = vehicleData.fields;
        const distance = (vehicleData.exprs.distancekm * 0.6213712).toFixed(1);
        const hasImages: boolean =
            Number(vehicleData.exprs.has_images) === 1 && fields.images;
        if (cardNumber === 45) {
            scrollTrigger = true;
            scrollTriggerIterator++;
            cardIterator++;
        } else if (cardNumber === 50) {
            cardIterator = 0;
        } else {
            cardIterator++;
        }
        pagination.push({
            vehicleId: vehicleData.id,
            vehiclePicture: hasImages ? fields.images[0] : undefined,
            vehicleYear: fields.year,
            vehicleMake: fields.make_decoded,
            vehicleModel: fields.model_decoded,
            vehiclePrice: fields.price,
            vehicleMileage: fields.mileage,
            vehicleLocation: fields.contact_name,
            distance: distance,
            vehicleVin: fields.vin,
            checkKey: fields.vin,
            scrollKey: scrollTrigger ? scrollKey.toString() : undefined,
        });
    });
    return pagination;
};

export type FormState = {
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
};

const formatPhoneNumber = (phoneNumber: string) => {
    const areaCode = phoneNumber.slice(0, 3);
    const threeDigits = phoneNumber.slice(3, 6);
    const fourDigits = phoneNumber.slice(6, 10);
    return areaCode + '-' + threeDigits + '-' + fourDigits;
};

const delay = (ms: number) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
};

export const leadPost = async (
    usedVehicleState: UsedVehicleState,
    formState: FormState,
    comment: string
) => {
    const { selectedInventory } = usedVehicleState;
    await Promise.all(
        selectedInventory.map(async (vehicle: any) => {
            const reqBody = {
                first: formState.firstName,
                last: formState.lastName,
                email: formState.email,
                phone: formatPhoneNumber(formState.phone),
                zip: usedVehicleState.searchQueryParams.zip,
                city: usedVehicleState.city,
                state: usedVehicleState.state,
                comment: comment,
                vin: vehicle.vehicleVin,
                year: vehicle.vehicleYear,
                make: vehicle.vehicleMake,
                model: vehicle.vehicleModel,
            };
            try {
                const res = await axios.post(
                    process.env.REACT_APP_CPI_PROXY_SERVER + `/cpi/lead`,
                    reqBody,
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            Accept: 'application/json',
                        },
                    }
                );
                if (res && res.status === 200 && res.data) {
                    await delay(500);
                    // console.log(reqBody);
                    // console.log(res);
                }
            } catch (err) {
                console.log(err);
            }
        })
    );
    //END
};
