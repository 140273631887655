import axios from 'axios';
import isMobileCheck from './isMobileCheck';

export const getLatLong = async () => {
    const options = {
        enableHighAccuracy: true,
    };
    try {
        const geolocation = await new Promise((res, rej) => {
            navigator.geolocation.getCurrentPosition(res, rej, options);
        });
        if ((geolocation as any).coords) {
            return {
                lat: (geolocation as GeolocationPosition).coords.latitude,
                long: (geolocation as GeolocationPosition).coords.longitude,
            } as { lat: number; long: number };
        }
    } catch (err) {
        console.log('ERROR:', err);
    }
};

export const latLongToZip = async (latLong: { lat: number; long: number }) => {
    try {
        const res = await axios.get(
            process.env.REACT_APP_CPI_PROXY_SERVER +
                `/cpi/atlas?lat=${latLong.lat}&long=${latLong.long}`
        );
        if (res && res.status === 200) {
            const zip = res.data.address.postalCode;
            const city = res.data.address.city;
            const state = res.data.address.regionCode;
            const latLong = res.data.coordinate;
            return { zip, city, state, ...latLong };
        }
    } catch (err) {
        console.log('ERROR:', err);
    }
};

export const zipToLatLong = async (zip: string) => {
    try {
        const res = await axios.get(
            process.env.REACT_APP_CPI_PROXY_SERVER + `/cpi/atlas?zip=${zip}`
        );
        if (res && res.status === 200) {
            const city = res.data.address.city;
            const state = res.data.address.regionCode;
            const latLong = res.data.coordinate;

            return { latLong, city, state };
        }
    } catch (err) {
        console.log('ERROR:', err);
    }
};

export const inventoryCall = async (
    queryParams: any,
    bodyTypes?: Array<string>,
    exclude?: Array<string>
) => {
    const { price, mileage, zip, start, size } = queryParams;
    let excludeUrl = '';
    let bodyTypeUrl = '';
    if (exclude && exclude.length > 0) {
        const excludeString = exclude.map((e: string) => `"${e}"`);
        excludeUrl = `&exclude=[${excludeString}]`;
    }
    if (bodyTypes) {
        const bodyType = bodyTypes.map((e: string) => `"${e}"`);
        bodyTypeUrl = `&bodyType=[${bodyType}]`;
    }
    const inventoryUrl = `/cpi/search/detail?price=[${price}]&mileage=[${mileage}]&zip=${zip}&start=${start}&size=${size}`;
    const finalUrl = inventoryUrl + bodyTypeUrl + excludeUrl;
    try {
        const res = await axios(
            process.env.REACT_APP_CPI_PROXY_SERVER + finalUrl
        );
        if (res && res.status === 200) {
            return res.data;
        }
    } catch (err) {
        console.log('ERROR:', err);
    }
};

type SortedVehicleObject = {
    value: string;
    count: number;
};

export const sortInventory = async (inventory: any) => {
    let totalVehicles = 0;
    const sortedVehicles: Array<SortedVehicleObject> = [
        { value: 'SUV', count: 0 },
        { value: 'Pickup', count: 0 },
        { value: 'Sedan', count: 0 },
        { value: 'Wagon', count: 0 },
    ];
    const buckets = inventory.facets.body_style.buckets;
    await buckets.map((e: SortedVehicleObject) => {
        switch (e.value) {
            case 'SUV':
                sortedVehicles[0].count += e.count;
                totalVehicles += e.count;
                break;
            case 'Pickup':
                sortedVehicles[1].count += e.count;
                totalVehicles += e.count;
                break;
            case 'Sedan':
                sortedVehicles[2].count += e.count;
                totalVehicles += e.count;
                break;
            case 'Convertible':
                sortedVehicles[2].count += e.count;
                totalVehicles += e.count;
                break;
            case 'Coupe':
                sortedVehicles[2].count += e.count;
                totalVehicles += e.count;
                break;
            case 'Full-Size':
                sortedVehicles[2].count += e.count;
                totalVehicles += e.count;
                break;
            case 'Wagon':
                sortedVehicles[3].count += e.count;
                totalVehicles += e.count;
                break;
            case 'Hatchback':
                sortedVehicles[3].count += e.count;
                totalVehicles += e.count;
                break;
            case 'Mini-Van':
                sortedVehicles[3].count += e.count;
                totalVehicles += e.count;
                break;
            default:
                return;
        }
    });
    return { inventory: sortedVehicles, totalVehicles: totalVehicles };
};
