import React from 'react';
import { BsCurrencyDollar } from 'react-icons/bs';

interface IMonthlyPayment {
    termLength?: number;
    payment: number;
}

const MonthlyPayment: React.FC<IMonthlyPayment> = ({ termLength, payment }) => {
    return (
        <div className="box-border flex flex-grow justify-between overflow-hidden bg-clip-padding odd:bg-stone-50 last-of-type:rounded-b sm:max-lg:max-w-[152px] lg:flex-col lg:items-center lg:py-4">
            <div className="order-1 flex items-center justify-start py-2 pl-4 text-left text-stone-700 sm:py-5 lg:order-2 lg:p-0">
                <p className="mb-0 lg:hidden">{termLength?.toString()}m</p>
                <p className="mb-0 text-sm max-lg:hidden">
                    {termLength?.toString()}&nbsp;months
                </p>
            </div>
            <div className="order-2 flex items-center justify-end py-2 pr-4 text-2xl font-bold text-stone-700 sm:py-5 lg:order-1 lg:p-0">
                <BsCurrencyDollar className="relative -top-[5px] left-1 text-sm" />
                <p className="mb-0 text-right">{payment.toLocaleString()}</p>
            </div>
        </div>
    );
};

export default MonthlyPayment;
