import { v4 as uuid } from 'uuid';

export class Module {
    constructor({ name, globals = {}, ...bindings } = {}) {
        this.id = uuid();
        this.name = name;
        this.globals = globals;

        /* Bind all the methods to the module instance, allowing for custom methods */
        Object.entries(bindings).forEach(([event, handler]) => {
            this[event] = handler.bind(this);
        });

        this.init.call(this, this.globals);
    }

    /* Run when the module is instantiated */
    init() {}

    /* Run when a dispatch event occurs, to determine if the handler should run */
    filter() {
        return true;
    }

    /* Run when a dispatch event occurs, IFF the filter returns true */
    handler() {}
}

export default Module;
