import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { Context as HarvesterContext } from '../helpers/harvester/react/Provider';

type ABTestingState = {
    variant?: string;
};

interface IABTestingContext {
    abTestingState: ABTestingState;
    updateABTestingState: (abTestingState: ABTestingState) => void;
}

const defaultState: IABTestingContext = {
    abTestingState: {
        variant: undefined,
    },
    updateABTestingState: (abTestingState: ABTestingState) => {},
};

export const ABTestingContext =
    React.createContext<IABTestingContext>(defaultState);

export const ABTestingContextProvider = (props: { children: any }) => {
    const { harvester } = useContext(HarvesterContext);
    const [state, setState] = useState(defaultState.abTestingState);

    const updateState = (state: ABTestingState) => setState(state);

    const getVariant = async () => {
        const initializeWindow = () => {
            return window.location.search;
        };
        const urlParams = new URLSearchParams(initializeWindow());
        if (
            urlParams.get('variant') &&
            window.origin !== 'https://carpayment.info'
        ) {
            const variant = urlParams.get('variant');
            console.log('A/B Testing Dev Variant:', variant);
            (variant === 'A' || variant === 'B') &&
                setState({
                    ...state,
                    variant: variant,
                });
        } else if (!state.variant) {
            const cid = harvester.globals.cid;
            try {
                const res = await axios.get(
                    process.env.REACT_APP_CPI_PROXY_SERVER + `/ab/cid/${cid}`,
                    {
                        headers: {
                            'X-DTC-Client-ID': cid,
                        },
                    }
                );
                if (res) {
                    window.origin !== 'https://carpayment.info' &&
                        console.log('cid:', cid, 'variant:', res.data.variant);
                    harvester.globals.variant = res.data.variant;
                    setState({
                        ...state,
                        variant: res.data.variant,
                    });
                }
            } catch (err) {
                console.log(err);
            }
        }
    };

    useEffect(() => {
        getVariant();
    }, []);

    return (
        <ABTestingContext.Provider
            value={{
                abTestingState: state,
                updateABTestingState: updateState,
            }}
        >
            {props.children}
        </ABTestingContext.Provider>
    );
};

export const useABTestingContext = () => React.useContext(ABTestingContext);
