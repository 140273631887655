import React from 'react';
import { Helmet } from 'react-helmet';

type HomeLayoutProps = {
    children?: JSX.Element | JSX.Element[];
    pageTitle?: string;
};

const HomeLayout: React.FC<HomeLayoutProps> = ({ children, pageTitle }) => {
    return (
        <>
            <Helmet>
                <title>
                    {pageTitle ? `${pageTitle}` : 'Car Payment Calculator'}
                </title>
            </Helmet>
            <div className="flex min-h-screen flex-col bg-stone-200 pt-6 xs:p-6 sm:p-4">
                <main className="mx-auto mb-auto flex h-full flex-col items-center justify-start sm:my-auto sm:max-w-[415px] lg:max-w-[944px]">
                    {children}
                </main>
            </div>
        </>
    );
};

export default HomeLayout;
